import { Box, Button, Stack, Typography } from "@mui/joy";
import { theme } from "styles/theme";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOnboardingData } from "hooks/useOnboardingData";

interface SelectOption {
  id: number;
  label: string;
  score: number;
}


export const selectOptionList: SelectOption[] = [
  { id: 1, label: "하루에도 여러번", score: 28 },
  { id: 2, label: "하루에 한 번 정도", score: 24 },
  { id: 3, label: "2-3일에 한 번 정도", score: 20 },
  { id: 4, label: "일주일에 한 번 정도", score: 16 },
  { id: 5, label: "더 빈도가 적음", score: 12 },
];

export default function Goal_6() {
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false); // 애니메이션 상태 추가

  const pageKey = "goal_6"; // 페이지를 구분할 키 값
  const { singleSelectionId, updateSingleSelection } = useOnboardingData(pageKey);

  const handleNext = () => {
    navigate(`/onboarding_new/goal/7`);
  };


  // 버튼 선택 로직
  const handleOptionChange = (optionId: number) => {
    updateSingleSelection(optionId);

    setTimeout(() => {
      setIsAnimating(false); // 애니메이션 종료
      handleNext(); // 페이지 전환
    }, 300); // 애니메이션 지속 시간 (300ms)
  };

  return (
    <Stack spacing={3} sx={{ height: "100%" }}>
      <Typography
        variant="Subtitle"
        fontWeight={500}
        sx={{
          color: theme.vars.palette.Content.Strong,
        }}
      >
        앞선 문제들은 어떤 빈도로 찾아오곤 하나요?
      </Typography>
      <Box sx={{ flex: 1, overflowY: "auto" }}>
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            whiteSpace: "nowrap",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          {selectOptionList.map(({ id, label }, optionIndex) => (
            <Stack key={`select_option_${id}`} sx={{ flexGrow: 1, gap: 1 }}>
              <Button
                fullWidth
                variant={"SingleSlim"}
                color={singleSelectionId === id ? "primary" : "secondary"}
                sx={{
                  height: "54px",
                  borderRadius: "12px",
                  fontSize: "Body",
                  transition: "background-color 0.3s ease", // ease 애니메이션 추가
                }}
                onClick={() => {
                  handleOptionChange(id);
                }}
              >
                {label}
              </Button>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}