import React from "react";
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";

export default function Motivation_6() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/motivation/7`);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "70vh" }}>
      <Stack
        spacing={1.5}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={700}
          sx={{
            color: theme.vars.palette.Content.Highlight,
          }}
        >
          {"계획 없는 목표는 그저 소망일 뿐입니다."}
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "우리는 목표를 잘게 쪼개 하나씩 공략해 나갈 것입니다."
          }
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "작은 성공 경험을 발판 삼아 더 큰 목표로 자신있게 걸음을 옮겨 갈 것입니다."
          }
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "단순히 괴로움에서 벗어나는 것 이상으로, 나의 삶은 능동적이고 진취적으로 변화할 겁니다."
          }
        </CustomTypography>
      </Stack>
      <BottomNextButton
        disabled={false}
        onClick={handleNext}
        text={"다음"}
        highlightBackground={true}
      />
    </Box>
  );
}