import * as _ from "lodash";
import { debounce } from "lodash";
import { Button } from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { ProgramContentType } from "data/BlockComponent";
import { isDev } from "config";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { permanentDataAtom } from "recoil/permanentDataAtom";
import useGetCertainEventFromApp from "hooks/useGetCertainEventFromApp";
import { setProgramContentData } from "logic/logics";

export default function AudioButtonWeb({
  meditationRecordKey,
  url,
  initialTime,
  setData,
  blockIndex,
  lineIndex,
  cellIndex,
  isCoach,
  disabled,
  extraAudioUrl,
  extraLoopNumber = 0,
}: {
  meditationRecordKey?: string;
  url: string;
  initialTime: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  blockIndex: number;
  lineIndex: number;
  cellIndex: number;
  isCoach: boolean;
  disabled: boolean;
  extraAudioUrl?: string;
  extraLoopNumber?: number;
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const audioRef = useRef<HTMLAudioElement>(null);
  const extraAudioRef = useRef<HTMLAudioElement>(null);
  const [play, setPlay] = useState(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(initialTime);

  const [extraDuration, setExtraDuration] = useState<number>(0);

  const updateCurrentTimeData = () => {
    const updatedTime = currentTime;

    const isTextareaFocused = document.activeElement?.tagName === "TEXTAREA";

    if (!isTextareaFocused) {
      setProgramContentData({
        setData,
        blockIndex,
        lineIndex,
        cellIndex,
        newlyAddedData: {
          currentTime: updatedTime,
          ...(updatedTime > 0 && {
            id: "meditation_time",
            value: `${Math.floor((updatedTime || 60) / 60)}`,
          }),
        },
      });
    }
  };

  const debouncedUpdateTimeHandler = debounce(updateCurrentTimeData, 1000);

  useEffect(() => {
    if (play) {
      debouncedUpdateTimeHandler();
    } else {
      stop();
    }
  }, [currentTime, play]);

  // 재생
  const start = () => {
    if (initialTime >= duration && extraAudioRef.current) {
      extraAudioRef.current.play();
    } else {
      if (audioRef.current) {
        audioRef.current.play();
      }
    }

    setPlay(true);
  };
  // 일시 정지
  const stop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    if (extraAudioRef.current) {
      extraAudioRef.current.pause();
    }
    setPlay(false);
  };

  function setAudioEnded() {
    setData((data) => {
      if (data) {
        const data_temp = _.cloneDeep(data);
        const currentContent =
          data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};

        data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
          ...currentContent,
          isEnded: true,
        };
        // if (!extraAudioUrl) {
        //   data_temp[blockIndex].autoOpenNext = true;
        // }
        if (!_.isEqual(data, data_temp)) {
          return data_temp;
        }
        return data;
      }
      return data;
    });
  }

  /**
   * 미디어 재생이 끝났을 때 발생합니다. 미디어가 끝까지 재생되었음을 감지하여, 재생 버튼을 초기화하거나 다음 트랙을 자동으로 재생하는 등의 작업을 수행할 수 있습니다.
   */
  function handleEnded() {
    if (extraAudioUrl) {
      extraAudioRef.current?.play();
    } else {
      stop();
    }
    if (!isCoach) {
      setAudioEnded();
    }
  }

  /**
   * 미디어의 전체 길이(재생 시간)가 변경될 때 발생합니다. 예를 들어, 미디어 소스가 변경되거나 미디어가 처음 로드될 때 발생할 수 있습니다. 이 이벤트를 통해 미디어의 총 길이를 UI에 업데이트할 수 있습니다.
   */
  function handleDurationChange() {
    // console.log("durationChange", audioRef.current?.duration);
    if (audioRef.current?.duration) {
      setDuration(parseInt(`${audioRef.current?.duration}`));
    }
  }

  /**
   *  미디어의 첫 번째 프레임이 로드되었을 때 발생합니다. 즉, 미디어가 재생을 시작할 준비가 되었음을 의미합니다. 이 이벤트를 통해 미디어의 메타데이터를 읽거나 초기 설정을 할 수 있습니다.
   */
  function handleLoadedData() {
    if (audioRef.current) {
      audioRef.current.currentTime = initialTime;
    }
  }

  /**
   * 미디어의 현재 재생 위치가 변경될 때 주기적으로 발생합니다. 일반적으로 미디어가 재생 중일 때 발생하며, 이를 통해 현재 재생 시간을 UI에 표시하거나 다른 동작을 수행할 수 있습니다.
   */
  function handleTimeUpdate() {
    const currentTime = parseInt(`${audioRef.current?.currentTime}`);

    setCurrentTime(currentTime);
  }

  function handleDurationChange_extraAudio() {
    if (extraAudioRef.current) {
      const extraDuration = extraAudioRef.current.duration;
      setExtraDuration(parseInt(`${extraAudioRef.current?.duration}`));
      extraAudioRef.current.currentTime = Math.floor(
        (initialTime - duration) % extraDuration
      );
    }
  }

  function handleTimeUpdate_extraAudio() {
    setCurrentTime(
      parseInt(
        `${
          duration +
          extraDuration * extraLoopNumber +
          (extraAudioRef.current?.currentTime || 0)
        }`
      )
    );
    if (
      extraDuration > 0 &&
      parseInt(`${extraAudioRef.current?.currentTime}`) === extraDuration
    ) {
      setProgramContentData({
        setData,
        blockIndex,
        lineIndex,
        cellIndex,
        newlyAddedData: { extraLoopNumber: extraLoopNumber + 1 },
      });
    }
  }

  const [permanantDataState, setPermanantDataState] =
    useRecoilState(permanentDataAtom);

  const timeSpentInBackgroundEvent = useGetCertainEventFromApp({
    eventName: "timeSpentInBackground",
  });

  useEffect(() => {
    if (timeSpentInBackgroundEvent) {
      const seconds = timeSpentInBackgroundEvent.params.seconds;

      if (!play) {
        setCurrentTime(
          Math.min(
            permanantDataState?.audioCurrentTime || 0 + seconds,
            duration
          )
        );
      }
    }
  }, [timeSpentInBackgroundEvent]);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      setPermanantDataState((state) => ({
        ...state,
        audioCurrentTime: currentTime,
      }));
      if (!isCoach) {
        setAudioEnded();
        // setTimeout(() => {
        //   setAudioEnded();
        // }, Math.max((duration - currentTime) * 1000, 0));
      }
    } else {
      // sendMessage(
      //   JSON.stringify({
      //     event: "test",
      //     params: {
      //       data: "visibilitychabge",
      //     },
      //   })
      // );
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    if (audioRef.current) {
      audioRef.current.addEventListener("loadeddata", handleLoadedData);
      audioRef.current.addEventListener("durationchange", handleDurationChange);
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.addEventListener("ended", handleEnded);
    }
    if (extraAudioRef.current) {
      extraAudioRef.current.addEventListener(
        "durationchange",
        handleDurationChange_extraAudio
      );
      extraAudioRef.current.addEventListener(
        "timeupdate",
        handleTimeUpdate_extraAudio
      );
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (audioRef.current) {
        setPermanantDataState((state) => ({
          ...state,
          audioCurrentTime: undefined,
        }));

        audioRef.current.removeEventListener("loadeddata", handleLoadedData);
        audioRef.current?.removeEventListener(
          "durationchange",
          handleDurationChange
        );
        audioRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current?.removeEventListener("ended", handleEnded);
      }
      if (extraAudioRef.current) {
        extraAudioRef.current.removeEventListener(
          "durationchange",
          handleDurationChange_extraAudio
        );
        extraAudioRef.current.removeEventListener(
          "timeupdate",
          handleTimeUpdate_extraAudio
        );
      }
    };
  }, [duration, extraDuration, extraLoopNumber]);

  function parseSecondsToFormat(seconds: number) {
    return `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(
      2,
      "0"
    )}`;
  }

  return (
    <div>
      <div className={`${!isDev && "hidden"}`}>
        <audio ref={audioRef} src={url} controls></audio>
      </div>
      {extraAudioUrl && (
        <div className={`${!isDev && "hidden"}`}>
          <audio ref={extraAudioRef} src={extraAudioUrl} controls loop></audio>
        </div>
      )}

      <Button
        disabled={disabled}
        color={play ? "primary" : "secondary"}
        fullWidth
        onClick={(event) => {
          if (play) {
            stop();
          } else {
            start();
          }
        }}
        variant={"outlined"}
        size={"md"}
        {...(!play && {
          startDecorator: (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" fill="white" />
              <path
                d="M6 5.78956C6 5.00724 6.85783 4.52789 7.5241 4.93791L17.6161 11.1483C18.2506 11.5388 18.2506 12.4612 17.6161 12.8517L7.5241 19.0621C6.85783 19.4721 6 18.9928 6 18.2104V5.78956Z"
                fill="#4B6D8A"
              />
            </svg>
          ),
        })}
      >
        {play
          ? `${parseSecondsToFormat(currentTime)} / ${parseSecondsToFormat(
              duration
            )}`
          : t_ui(
              currentTime === 0 || currentTime === duration
                ? "음성 안내 재생하기"
                : "이어서 재생하기"
            )}
      </Button>
    </div>
  );
}