import React from "react";
import { Box } from "@mui/material";
import { Stack, Typography } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import { useNavigate } from "react-router-dom";
import { useOnboardingData } from "hooks/useOnboardingData";
import SelectCheckbox from "components/onboarding/SelectCheckBox";

interface Option {
  id: number;
  text: string;
}

// 옵션 리스트
export const goal5OptionList: Option[] = [
  { id: 1, text: "우울한 마음" },
  { id: 2, text: "무기력한 신체" },
  { id: 3, text: "자책하고 후회하는 생각" },
  { id: 4, text: "걱정되고 불안한 마음" },
  { id: 5, text: "통제가 어려운 발작 증세" },
  { id: 6, text: "조절이 어려운 감정" },
  { id: 7, text: "미루고 회피하는 행동" },
];

export default function Goal_5_MultiSelect_3() {
  const navigate = useNavigate();

  const pageKey = "goal_5"; // 페이지를 구분할 키 값
  const { multiSelectionIdList, updateMultiSelection } =
    useOnboardingData(pageKey);

  // 체크박스 선택/해제 로직
  const handleOptionChange = (optionId: number) => {
    updateMultiSelection(optionId);
  };

  // TODO 리팩토링
  const handleNext = () => {
    navigate(`/onboarding_new/goal/6`);
  };

  return (
    // ➊ 내용이 버튼 뒤에 가려지지 않도록 충분한 패딩을 줍니다.
    <Stack
      spacing={5}
      sx={{ paddingBottom: "80px" }} // 하단 버튼 높이보다 넉넉하게
    >
      <Typography
        variant="Subtitle"
        fontWeight={500}
        sx={{ color: theme.vars.palette.Content.Strong }}
      >
        {"최근 들어 나의 일상을 흔드는 문제는 무엇인가요?"}
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {goal5OptionList.map((option) => (
          <SelectCheckbox
            key={option.id}
            checked={multiSelectionIdList.includes(option.id)}
            onChange={() => handleOptionChange(option.id)}
            label={option.text}
          />
        ))}
      </Box>

      <BottomNextButton
        disabled={multiSelectionIdList.length === 0}
        onClick={handleNext}
        text={"다음"}
      />
    </Stack>
  );
}