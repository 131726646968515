import React, { useEffect, useRef, useState } from 'react'
import { Box, Stack, Typography } from '@mui/joy'
import { theme } from 'styles/theme'

// 예시 이징 함수들
function easeInOutCubic(t: number): number {
  return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
}

function easeOutCubic(t: number): number {
  return 1 - Math.pow(1 - t, 3);
}

function easeInQuad(t: number): number {
  return t * t;
}

/*
  FourProgressBars
  - labels: ProgressBar 위 라벨 (길이4)
  - barHeight: 바 높이
  - barColor: 채워지는 부분 색상
  - barTimes: 각 바 걸리는 시간 배열
  - barEases: 각 바별 이징 함수
  - handleNext: 4개 완료 시 호출하는 함수
*/
export default function FourProgressBars({
  labels = ["고민과 목표", "인식과 기대", "습관과 노력", "의지와 동기부여"],
  barHeight = 12,
  barColor = theme.vars.palette.Surface.Highlight,
  barTimes = [2000, 2500, 1500, 3000],
  barEases = [easeInQuad, easeOutCubic, easeInOutCubic, easeOutCubic],
  handleNext,
}: {
  labels?: string[];
  barHeight?: number;
  barColor?: string;
  barTimes?: number[];
  barEases?: ((t: number) => number)[];
  handleNext?: () => void;
}) {
  // 4개 바의 진행도 (0 ~ 100)
  const [progresses, setProgresses] = useState([0, 0, 0, 0]);

  // 현재 채우는 바 index
  const currentIndexRef = useRef(0);

  // 각 setInterval ID를 저장
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);

  useEffect(() => {
    fillBar(currentIndexRef.current);
    return () => {
      timeoutsRef.current.forEach((t) => clearInterval(t));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // barIndex에 해당하는 바를 0→100%로 채우는 함수
  function fillBar(barIndex: number) {
    if (barIndex >= 4) {
      // 4개 전부 끝나면 handleNext 호출
      handleNext?.();
      return;
    }

    const duration = barTimes[barIndex];
    const easingFn = barEases[barIndex];

    let currentStep = 0;
    const steps = 60;
    const intervalTime = duration / steps;

    const timer = setInterval(() => {
      currentStep++;
      const t = currentStep / steps;
      const eased = easingFn(t);
      const currentProgress = Math.floor(eased * 100);

      setProgresses((prev) => {
        const next = [...prev];
        next[barIndex] = currentProgress;
        return next;
      });

      if (currentStep >= steps) {
        setProgresses((prev) => {
          const next = [...prev];
          next[barIndex] = 100;
          return next;
        });
        clearInterval(timer);

        // 다음 바로 넘어감
        currentIndexRef.current = barIndex + 1;
        fillBar(barIndex + 1);
      }
    }, intervalTime);

    timeoutsRef.current.push(timer);
  }

  return (
    <Stack spacing={3} sx={{ width: "100%"}}>
      {progresses.map((progress, i) => (
        // 한 Bar 블록 (라벨 + % 표시 + 바)
        <Stack
          key={i}
          spacing={1} // 1단위 = 8px
          sx={{ width: "100%" }}
        >
          {/* 라벨과 퍼센트를 "한 줄"로 만들고, bar 위에 위치 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              variant={"Body"}
              fontWeight={700}
              sx={{
                color:
                  progress > 0
                    ? theme.vars.palette.Content.Highlight
                    : theme.vars.palette.Content.Weak,
              }}
            >
              {labels[i] ?? `라벨${i + 1}`}
            </Typography>
            <Typography
              variant={"Body"}
              fontWeight={700}
              sx={{
                color:
                  progress > 0
                    ? theme.vars.palette.Content.Highlight
                    : theme.vars.palette.Content.Weak,
              }}
            >
              {progress}%
            </Typography>
          </Box>

          {/* 아래쪽 프로그레스 바 */}
          <Box
            sx={{
              backgroundColor: theme.vars.palette.Surface.Backward,
              borderRadius: "8px",
              height: `${barHeight}px`,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                width: `${progress}%`,
                backgroundColor: barColor,
                transition: "width 0.1s linear",
              }}
            />
          </Box>
        </Stack>
      ))}
    </Stack>
  );
}
