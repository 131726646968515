import React from "react";
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";

export default function Motivation_4() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/motivation/5`);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "70vh" }}>
      <Stack
        spacing={1.5}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={700}
          sx={{
            color: theme.vars.palette.Content.Highlight,
          }}
        >
          {"완벽한 사람은 없습니다."}
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "그러니 코치 선생님이 도울 겁니다."
          }
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "코치 선생님은 프로그램은 완수할 수 있도록 내게 꾸준히 동기부여를 해줄 것입니다."
          }
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "자책하거나 가라앉을 때마다, 다시 일어서서 앞으로 나아갈 수 있도록 이끌어 줄 것입니다."
          }
        </CustomTypography>
      </Stack>
      <BottomNextButton
        disabled={false}
        onClick={handleNext}
        text={"다음"}
        highlightBackground={true}
      />
    </Box>
  );
}