import React, { useEffect, useRef } from 'react'
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import { useMutation } from '@tanstack/react-query'
import { onboardingApi } from '../../../../api/onboardingApi'

export default function Motivation_1() {
  const navigate = useNavigate();

  // TODO 리팩토링
  const handleNext = () => {
    navigate(`/onboarding_new/motivation/2`);
  };

  const pageKey = "motivation_1";

  const { mutate: savePage } = useMutation(
    (pageId: string) => onboardingApi.savePage(pageId),
    {
      onSuccess: () => {},
    }
  );

  function useEffectOnce(callback: () => void) {
    const ref = useRef(false);

    useEffect(() => {
      if (ref.current) return;
      ref.current = true;

      if (typeof callback === "function") {
        callback();
      }
    }, []);
  }

  useEffectOnce(() => {
    savePage(pageKey);
  });

  return (
    <Box
      sx={{
        // (헤더 높이를 뺀 나머지 높이에 맞추고 싶다면 적절히 계산하여 넣으면 됩니다)
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 220px)", // TODO 경험치
      }}
    >
      <Box
        sx={{
          flex: 1, // 상단 헤더와 하단 버튼 사이 남은 공간 전부 사용
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // 세로 가운데 정렬
        }}
      >
        <Stack spacing={1.5}>
          <CustomTypography
            variant="Subtitle"
            fontWeight={700}
            sx={{
              color: theme.vars.palette.Content.Highlight,
            }}
          >
            무언가를 꾸준히 해내는 것은 어렵습니다.
          </CustomTypography>
          <CustomTypography
            variant="Subtitle"
            fontWeight={500}
            sx={{
              color: theme.vars.palette.Content.Strong,
            }}
          >
            하지만 디스턴싱에는 해당하지 않는 이야기입니다.
          </CustomTypography>
          <CustomTypography
            variant="Subtitle"
            fontWeight={500}
            sx={{
              color: theme.vars.palette.Content.Strong,
            }}
          >
            디스턴싱은 개인의 의지과 동기부여 수준에 따라 프로그램을 유연히 조절하며 목표 달성을 돕습니다.
          </CustomTypography>
        </Stack>
      </Box>
      <BottomNextButton disabled={false} onClick={handleNext} text={"다음"} />
    </Box>
  );
}