import React from "react";
import { Typography, TypographyProps } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";

interface CustomTypographyHighlightProps extends TypographyProps {
  // 하이라이팅이 필요한 텍스트 (옵션)
  highlightedText?: string;
  // 하이라이트 부분에 적용할 variant (옵션)
  highlightVariant?: TypographyProps["variant"];
  // 하이라이트 부분에 적용할 sx (옵션)
  highlightSx?: SxProps;
}

export default function CustomTypographyHighlight({
  children,
  sx,
  highlightedText,
  highlightVariant,
  highlightSx,
  ...rest
}: CustomTypographyHighlightProps) {
  // 1) 만약 children이 문자열이 아닐 경우, 그대로 렌더링 처리
  if (typeof children !== "string") {
    return (
      <Typography
        sx={{
          p:0,
          whiteSpace: "pre-wrap",
          wordBreak: "keep-all",
          overflowWrap: "break-word",
          lineBreak: "strict",
          ...sx,
        }}
        {...rest}
      >
        {children}
      </Typography>
    );
  }

  // 2) children이 문자열인 경우, highlightedText가 존재하면 파싱
  let parsedChildren: React.ReactNode = children;

  if (highlightedText) {
    // 정규식으로 highlightedText를 기준으로 split
    // (특수문자 등은 별도 이스케이프 처리가 필요할 수 있음)
    const parts = children.split(new RegExp(`(${highlightedText})`, "g"));

    parsedChildren = parts.map((part, idx) => {
      if (part === highlightedText) {
        // 하이라이트 부분
        return (
          <Typography
            key={idx}
            component="span"
            variant={highlightVariant}
            sx={
              {
              p: 0,
              m: 0,
            ...highlightSx
          }}
          >
            {part}
          </Typography>
        );
      } else {
        // 일반 텍스트
        return <React.Fragment key={idx}>{part}</React.Fragment>;
      }
    });
  }

  return (
    <Typography
      sx={{
        whiteSpace: "pre-wrap",
        wordBreak: "keep-all",
        overflowWrap: "break-word",
        lineBreak: "strict",
        ...sx,
      }}
      {...rest}
    >
      {parsedChildren}
    </Typography>
  );
}