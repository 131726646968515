import React from "react";
import { Box } from "@mui/material";
import { Stack } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import { useNavigate } from "react-router-dom";
import { useOnboardingData } from "hooks/useOnboardingData";
import SelectCheckbox from "components/onboarding/SelectCheckBox";
import CustomTypography from "../../../../components/common/CustomTypography";

interface Option {
  id: number;
  text: string;
}

// 옵션 리스트
export const goal3OptionList: Option[] = [
  { id: 1, text: "외부 환경의 변화 (진학, 이직 등)" },
  { id: 2, text: "인간관계에서의 불화 또는 결별" },
  { id: 3, text: "능률 저하 (학업, 업무 등)" },
  { id: 4, text: "불확실한 미래 (진로, 취업 등)" },
  { id: 5, text: "과거의 기억 또는 트라우마" },
  { id: 6, text: "일정하지 않은 수면, 식사 패턴" },
  { id: 7, text: "집착이나 중독 (게임, 코인, 음주, 쇼츠 등)" },
  { id: 8, text: "기타" },
];

export default function Goal_3_MultiSelect_2() {
  const navigate = useNavigate();

  const pageKey = "goal_3"; // 페이지를 구분할 키 값
  const { multiSelectionIdList, updateMultiSelection } =
    useOnboardingData(pageKey);

  // 체크박스 선택/해제 로직
  const handleOptionChange = (optionId: number) => {
    updateMultiSelection(optionId);
  };

  // TODO 리팩토링
  const handleNext = () => {
    navigate(`/onboarding_new/goal/4`);
  };

  return (
    // ➊ 내용이 버튼 뒤에 가려지지 않도록 충분한 패딩을 줍니다.
    <Stack
      spacing={5}
      sx={{ paddingBottom: "80px" }} // 하단 버튼 높이보다 넉넉하게
    >
      <Stack spacing={1.5}>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{ color: theme.vars.palette.Content.Strong }}
        >
          {"우리의 마음은 여러 외부적 요인에 영향 받곤 합니다."}
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{ color: theme.vars.palette.Content.Strong }}
        >
          {"다음 중 요즈음 나를 힘들게 하는 것이 있나요?"}
        </CustomTypography>
      </Stack>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {goal3OptionList.map((option) => (
          <SelectCheckbox
            key={option.id}
            checked={multiSelectionIdList.includes(option.id)}
            onChange={() => handleOptionChange(option.id)}
            label={option.text}
          />
        ))}
      </Box>

      <BottomNextButton
        disabled={multiSelectionIdList.length === 0}
        onClick={handleNext}
        text={"다음"}
      />
    </Stack>
  );
}