import React, { useEffect, useRef } from "react";
import { Stack, Typography } from "@mui/joy";
import FullPageContainer from "./FullPageContainer";
import { theme } from "styles/theme";
import BackButton from "components/common/BackButton";
import ProgressBarOnboarding from "components/onboarding/ProgressBarOnboarding";
import { sendMessage } from "utils/webview";
import useIsInappWebview from "hooks/useIsInappWebview";
import { useOnboardingProgress } from "../../hooks/useOnboardingProgress";
import { extractHexColor } from "../../styles/hexOpacity";

export default function FullPageWithHeaderAndProgress({
  headerTitle,
  hideHeader,
  hideProgress,
  highlightBackground,
  body,
  onBack,
}: {
  headerTitle?: string;
  hideHeader?: boolean;
  hideProgress?: boolean;
  highlightBackground?: boolean;
  body?: React.ReactNode;
  onBack?: () => void;
}) {
  const backgroundColor = !highlightBackground
    ? theme.vars.palette.Background.Level1
    : theme.vars.palette.Background.SoftHighlight;
  const headerHeight = 48; // 헤더 높이
  const progressBarHeight = 12; // 프로그레스바 높이
  const progressPaddingX = 24; // 프로그래스바 양쪽 패딩 값

  const paddingXHeader = 16;
  const paddingXContent = 24;
  const paddingTopContent = hideProgress ? 12 : 32;

  const containerRef = useRef<HTMLDivElement>(null);

  // 화면 잘림 문제
  useEffect(() => {
    // 페이지 전환(혹은 특정 이벤트) 시점에
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [body]);

  const isInappWebview = useIsInappWebview();

  const { progress } = useOnboardingProgress();

  useEffect(() => {
    if (isInappWebview) {
      const hexValue = extractHexColor(backgroundColor);

      if (hexValue) {
        sendMessage(
          JSON.stringify({
            event: "set_safearea_color",
            params: {
              color: `${hexValue}`,
            },
          })
        );
      }
    }
  }, [backgroundColor]);

  return (
    <FullPageContainer backgroundColor={backgroundColor}>
      <Stack
        direction={"column"}
        justifyContent={"flex-start"}
        sx={{
          maxWidth: "720px",
          width: "100vw",
          mx: "auto",
          height: "100vh",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: "100%",
            px: `${paddingXHeader}px`,
            position: "sticky",
            top: 0,
            zIndex: 100,
            height: `${headerHeight}px`, // 헤더 높이 설정
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {!hideHeader && onBack && <BackButton onBack={onBack} />}
            {headerTitle && (
              <Typography
                variant="Body"
                flexGrow={1}
                sx={{
                  px:0,
                  fontColor: theme.vars.palette.Content.Strong,
                  fontWeight: 700,
                }}
              >
                {headerTitle}
              </Typography>
            )}
          </Stack>
        </Stack>
        {!hideProgress && (
          <Stack
            sx={{
              width: "100%",
              px: `${progressPaddingX}px`,
              pt: "12px",
              position: "sticky",
              top: `${headerHeight}px`, // 헤더 높이만큼 아래로 이동
              zIndex: 99,
              height: `${progressBarHeight}px`, // 프로그레스바 높이 설정
            }}
          >
            <ProgressBarOnboarding progress={progress} />
          </Stack>
        )}
        <Stack
          ref={containerRef}
          sx={{
            width: "100%",
            flex: 1,
            overflow: "auto",
            px: `${paddingXContent}px`,
            py: `${paddingTopContent}px`,
            position: "relative",
          }}
        >
          {body}
        </Stack>
      </Stack>
    </FullPageContainer>
  );
}