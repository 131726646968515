import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import { ProgramContentType } from "data/BlockComponent";
import { setDataWithEditorKeyAndTranslationVersion } from "./useGetBlankTaskData";
import { Content_5_7_T, MeditationTemplate } from "data/task/v1/5-7-T";
import useGetMeditationRecordVersion from "./useGetMeditationRecordVersion";
import { MeditationTemplate_new, MeditationType } from "data/task/v1/5-7-T2";
import { useRecoilValue } from "recoil";
import { deviceAtom } from "recoil/deviceAtom";
import { taskListAtom } from "recoil/taskListAtom";
import useIsInappWebview from "./useIsInappWebview";

export default function useGetBlankMeditationRecordData({
  mrk,
  isFirst,
  isFromThoughtRecord,
}: {
  mrk?: string;
  isFirst?: boolean;
  isFromThoughtRecord?: boolean;
}) {
  const { isOldVersion } = useGetMeditationRecordVersion();
  // const isOldVersion = false;
  const taskId = "5-7-T";

  const [data, setData] = useState<ProgramType>();
  const deviceState = useRecoilValue(deviceAtom);
  const taskListState = useRecoilValue(taskListAtom);
  const isInappWebview = useIsInappWebview();
  const isPushNotificationGranted =
    deviceState.pushNotificationStatus === "granted" || !isInappWebview;

  const isSituationUpgraded = taskListState.some(
    (element) => element.taskId === "9-4-A" && element.isDone
  );

  function ifTaskDone(taskId: string) {
    return taskListState.find(
      (element) => element.taskId === taskId && element.isDone
    );
  }

  const meditationList = [
    ...(ifTaskDone("8-8-A") ? ["grounding"] : []),
    ...(ifTaskDone("8-8-A") ? ["relaxation"] : []),
    ...(ifTaskDone("8-9-A") ? ["breath"] : []),
    ...(ifTaskDone("9-13-A") ? ["willingness"] : []),
    ...(ifTaskDone("8-6-A") ? ["routine"] : []),
  ] as MeditationType[];

  // const meditationList = [
  //   "grounding",
  //   "relaxation",
  //   "breath",
  //   "willingness",
  //   "routine",
  // ] as MeditationType[];

  const taskData: ProgramType = {
    taskId: taskId,
    content: isOldVersion
      ? Content_5_7_T(isPushNotificationGranted)
      : MeditationTemplate_new(
          meditationList,
          mrk,
          isFromThoughtRecord,
          isSituationUpgraded
        ),
  };

  useEffect(() => {
    let rawData = _.cloneDeep(taskData);

    if (mrk && rawData) {
      if (isOldVersion && !isFirst) {
        rawData.content = _.cloneDeep(MeditationTemplate);
      }
      (rawData.content as ProgramContentType[])[0].lines.push([
        {
          type: "temp",
          content: {
            id: `meditationRecordKey`,
            value: mrk,
          },
        },
      ]);

      setData(setDataWithEditorKeyAndTranslationVersion(rawData));
    }
  }, [mrk]);

  return data;
}