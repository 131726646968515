import React, { useEffect, useState } from "react";
import { Stack } from "@mui/joy";
import {
  loadTossPayments,
  TossPaymentsPayment,
} from "@tosspayments/tosspayments-sdk";
import { useRecoilState, useRecoilValue } from "recoil";
import { userAtom } from "../../recoil/userAtom";
import { paymentOrderAtom } from "../../recoil/paymentOrderAtom";
import { useQuery } from "@tanstack/react-query";
import {
  PAYMENT_DOMAIN,
  PAYMENT_ORDER_DETAIL_ENDPOINT,
  paymentApi,
} from "../../api/paymentApi";
import useIsInappWebview from "../../hooks/useIsInappWebview";
import ContentTemplate from "../../components/task/content/ContentTemplate";
import useGetDeviceInfo from "../../hooks/useGetDeviceInfo";

export default function TossPayments() {
  const tossPaymentsClientKey = process.env.REACT_APP_TOSSPAYMENTS_CLIENT_KEY!!;

  const [tossPaymentsPayment, setTossPaymentsPayment] =
    useState<TossPaymentsPayment | null>();

  const user = useRecoilValue(userAtom);
  const [paymentOrderState, setPaymentOrderState] =
    useRecoilState(paymentOrderAtom);

  const isInappWebview = useIsInappWebview();

  const { refetch: getPaymentOrder } = useQuery(
    [PAYMENT_DOMAIN, PAYMENT_ORDER_DETAIL_ENDPOINT],
    () => paymentApi.getOrderDetail(),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data && data.result) {
          setPaymentOrderState(data.result);
        }
      },
    }
  );

  useEffect(() => {
    if (user?.accessToken) {
      getPaymentOrder();
    } else {
      console.error("유저 정보 없음");
    }
  }, []);

  async function requestTossPayments() {
    if (tossPaymentsPayment) {
      try {
        await tossPaymentsPayment?.requestPayment({
          method: "CARD",
          amount: {
            currency: "KRW",
            value: paymentOrderState.price!!,
          },
          orderId: paymentOrderState.orderId!!,
          orderName: paymentOrderState.orderName!!,
          successUrl: window.location.origin + "/toss-payments/success",
          failUrl: window.location.origin + "/toss-payments/fail",
          customerEmail: paymentOrderState.customerEmail,
          customerName: paymentOrderState.customerName,
          card: {
            useEscrow: false,
            flowMode: "DEFAULT",
            useCardPoint: false,
            useAppCardOnly: false,
          },
        });
      } catch (error) {
        // 에러 처리하기
        console.error(error);
      }
    }
  }

  useEffect(() => {
    async function fetchTossPaymentsPayment() {
      if (tossPaymentsClientKey && paymentOrderState?.customerKey) {
        const tossPayments = await loadTossPayments(tossPaymentsClientKey);
        const payment = tossPayments.payment({
          customerKey: paymentOrderState.customerKey,
        });

        setTossPaymentsPayment(payment);
      }
    }

    fetchTossPaymentsPayment();
  }, [tossPaymentsClientKey, paymentOrderState]);

  useEffect(() => {
    if (tossPaymentsPayment) {
      requestTossPayments();
    }
  }, [tossPaymentsPayment]);

  const { homeIndicatorHeight } = useGetDeviceInfo();
  const [containerHeight, setContainerHeight] = useState<number>(
    window.innerHeight - homeIndicatorHeight
  );

  const [floatingAreaHeight, setFloatingAreaHeight] = useState<number>(0);

  return (
    <ContentTemplate noPadding isWide setContainerHeight={setContainerHeight}>
      {containerHeight > 0 && (
        <Stack sx={{ pb: "150px", opacity: floatingAreaHeight > 0 ? 1 : 0 }}>
          <Stack
            spacing={"30px"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              maxWidth: "720px",
              mx: "auto",
              px: "40px",
              // height: `calc(${containerHeight}px - ${floatingAreaHeight}px)`,
              // pt: "50px",
            }}
          ></Stack>
        </Stack>
      )}
    </ContentTemplate>
  );
}