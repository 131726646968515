import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Stack } from "@mui/joy";
import DefaultPage from "pages/container/DefaultPage";
import { useConfirmTossPayments } from "hooks/useConfirmTossPayments";
import useIsInappWebview from "hooks/useIsInappWebview";
import { sendMessage } from "utils/webview";

export default function TossPaymentsSuccess() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isInappWebview = useIsInappWebview();

  const {
    mutate: confirmPayments,
    isLoading,
    isSuccess,
    isError,
  } = useConfirmTossPayments();

  useEffect(() => {
    const orderId = searchParams.get("orderId");
    const paymentKey = searchParams.get("paymentKey");
    const payAmount = Number(searchParams.get("amount"));

    if (orderId && paymentKey && payAmount) {
      // 결제 확인 API 호출
      confirmPayments(
        { orderId, paymentKey, payAmount },
        {
          onSuccess: () => {
            if (isInappWebview) {
              sendMessage(
                JSON.stringify({
                  event: "toss_payments",
                  params: { status: "success" },
                })
              );

              // 닫기 토스페이먼츠 창 닫기
              sendMessage(
                JSON.stringify({
                  event: "navigate_back",
                })
              );

              // 홈 상태 감지를 위해 (iOS)
              sendMessage(
                JSON.stringify({
                  event: "navigate_tab",
                  params: {
                    tabName: "코칭",
                  },
                })
              );

            } else {
              // 웹앱이면 메인화면으로
              navigate("/", { replace: true });
            }
          },
        }
      );
    }
  }, []);

  // 로딩/성공/실패 UI 렌더
  if (isLoading) {
    return (
      <DefaultPage
        content={
          <Stack alignItems="center">
            <CircularProgress color="neutral" />
          </Stack>
        }
      />
    );
  }

  if (isError) {
    return (
      <DefaultPage
        content={
          <Stack alignItems="center">결제 처리 중 오류가 발생했습니다.</Stack>
        }
      />
    );
  }

  if (isSuccess) {
    // onSuccess에서 이미 로직 수행 → 잠시 로딩 UI 표시 가능
    return (
      <DefaultPage
        content={
          <Stack alignItems="center">
            <CircularProgress color="neutral" />
          </Stack>
        }
      />
    );
  }

  return null;
}