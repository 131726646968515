import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/joy";
import { theme } from "styles/theme";
import useDetectIOS from "hooks/useDetectIOS";
import CustomTypography from "components/common/CustomTypography";
import TextareaOnboarding from "components/onboarding/TextareaOnboarding";
import KeyboardAwareBottomButton from "components/onboarding/KeyboardAwareBottomButton";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { userApi } from "api/userApi";
import { useOnboardingData } from "hooks/useOnboardingData";
import { useSetRecoilState } from "recoil";
import { userAtom } from "../../../recoil/userAtom";

export default function NicknameSubmit() {
  const navigate = useNavigate();
  const { isIOS } = useDetectIOS();
  /* ➊ HTMLTextAreaElement 참조용 useRef 생성 */
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { text, updateText } = useOnboardingData("nickname_submit");

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const setUser = useSetRecoilState(userAtom);

  const { mutate: submit } = useMutation(
    (nickname: string) => userApi.submitNickname(nickname),
    {
      onSuccess: (_, nickname) => {
        setLoading(false);
        navigate("/onboarding_new/habit/1");

        setUser((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            nickname: nickname,
          };
        });
      },
    }
  );

  function handleSubmit() {
    if (text && text.length >= 1) {
      setLoading(true);
      submit(text);
    }
  }

  /* ➋ 페이지 로드 후 자동으로 텍스트에어리어에 포커스 */
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  return (
    <Stack spacing={3} sx={{ height: "100%" }}>
      <CustomTypography
        variant="Subtitle"
        fontWeight={500}
        sx={{
          color: theme.vars.palette.Content.Strong,
        }}
      >
        코치 선생님이 나를 어떻게 부르면 좋을까요?
      </CustomTypography>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ width: "100%" }}
      >
        <Box
          sx={{
            width: "100px",
            height: "54px",
            backgroundColor: theme.vars.palette.Surface.Forward,
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            boxShadow: isFocused
              ? `inset 0 0 0 1.4px ${theme.vars.palette.Content.Highlight}`
              : `inset 0 0 0 0`,
          }}
        >
          <TextareaOnboarding
            initialValue={text}
            handleChange={(newValue: string) => updateText(newValue)}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            emojiDisabled={true}
            readOnly={false}
            textAlign={"center"}
            /* ➌ 여기서 자식(TextareaOnboarding)에 ref를 넘김 */
            // inputRef={textAreaRef}
          />
        </Box>

        <Typography
          variant="Body"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          님
        </Typography>
      </Stack>
      <KeyboardAwareBottomButton
        loading={loading}
        disabled={text === undefined || text === ""}
        onClick={handleSubmit}
        text={"다음"}
        highlightBackground={false}
      />
    </Stack>
  );
}