import { useNavigate, useParams } from "react-router-dom";
import Acquisition from "./Acquisition";
import GuaranteePage from "../../guarantee/GuaranteePage";
import onboarding_start from "../../../static/image/onboarding/onboarding_start.png";
import onboarding_coach from "../../../static/image/onboarding/onboarding_coach.png";
import FullPageWithTitleAndButton from "pages/container/FullPageWithTitleAndButton";
import useIsInappWebview from "../../../hooks/useIsInappWebview";
import { sendMessage } from "../../../utils/webview";

export default function OnboardingLegacy() {
  let navigate = useNavigate();
  const isInAppWebview = useIsInappWebview();

  const { id } = useParams();
  const numId = parseInt(id || "1");

  const dataList = [
    {
      title: "디스턴싱은 이해하고 훈련하는\n멘탈케어 프로그램입니다.",
      image: onboarding_start,
    },
    {
      title: "1:1 전담 코치가 내게 맞는 활동을\n매일 준비해드릴 거예요.",
      image: onboarding_coach,
    },
    {
      title: "환영합니다. 무엇을 보고 디스턴싱을 시작해보려 결심하셨나요?",
      type: "acquisition",
    },
    {
      title: "효과가 없으면\n100% 돌려드립니다.",
      type: "guarantee",
    },
  ];

  const finalPage = numId === dataList.length;

  const goNext = () => {
    if (finalPage) {
      if (isInAppWebview) {
        sendMessage(
          JSON.stringify({
            event: "navigate_back",
          })
        );
      } else {
        navigate("/", { replace: true });
      }
    } else {
      navigate(`/onboarding/${numId + 1}`);
    }
  };

  const type = dataList[numId - 1].type;
  return type === "acquisition" ? (
    <Acquisition
      title={dataList[numId - 1].title}
      progress={(numId / dataList.length) * 100}
      actionAfterSubmit={goNext}
    />
  ) : type === "guarantee" ? (
    <GuaranteePage
      title={dataList[numId - 1].title}
      buttonDisabled={false}
      progress={(numId / dataList.length) * 100}
      buttonClickAction={goNext}
    ></GuaranteePage>
  ) : (
    <FullPageWithTitleAndButton
      title={dataList[numId - 1].title}
      image={dataList[numId - 1].image}
      buttonText={finalPage ? "확인" : "다음"}
      buttonDisabled={false}
      progress={(numId / dataList.length) * 100}
      buttonClickAction={goNext}
    ></FullPageWithTitleAndButton>
  );
}