import React from "react";
import { Box } from "@mui/material";
import { Stack, Typography } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import { useNavigate } from "react-router-dom";
import { useOnboardingData } from "hooks/useOnboardingData";
import SelectCheckbox from "components/onboarding/SelectCheckBox";

interface Option {
  id: number;
  text: string;
}

export default function Motivation_7_MultiSelect({
  hideNextButton,
}: {
  hideNextButton: boolean;
}) {
  const navigate = useNavigate();

  const pageKey = "motivation_7"; // 페이지를 구분할 키 값
  const { multiSelectionIdList, updateMultiSelection } =
    useOnboardingData(pageKey);

  // 옵션 리스트
  const options: Option[] = [
    { id: 1, text: "배우자/연인" },
    { id: 2, text: "부모/자식" },
    { id: 3, text: "인간관계" },
    { id: 4, text: "경력" },
    { id: 5, text: "배움" },
    { id: 6, text: "여가" },
    { id: 7, text: "종교" },
    { id: 8, text: "사회 기여" },
    { id: 9, text: "기타" },
  ];

  // 체크박스 선택/해제 로직
  const handleOptionChange = (optionId: number) => {
    updateMultiSelection(optionId);
  };

  // TODO 리팩토링
  const handleNext = () => {
    navigate(`/onboarding_new/motivation/8`);
  };

  return (
    // ➊ 내용이 버튼 뒤에 가려지지 않도록 충분한 패딩을 줍니다.
    <Stack
      spacing={5}
      sx={{ paddingBottom: "80px" }} // 하단 버튼 높이보다 넉넉하게
    >
      <Typography
        variant="Subtitle"
        fontWeight={500}
        sx={{ color: theme.vars.palette.Content.Strong }}
      >
        {"건강한 마음을 갖는 것 외에, 내 삶에서 중요한 것은 무엇인가요?"}
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {options.map((option) => (
          <SelectCheckbox
            key={option.id}
            checked={multiSelectionIdList.includes(option.id)}
            onChange={() => handleOptionChange(option.id)}
            label={option.text}
          />
        ))}
      </Box>

      {!hideNextButton && (
        <BottomNextButton
          disabled={multiSelectionIdList.length === 0}
          onClick={handleNext}
          text={"다음"}
        />
      )}
    </Stack>
  );
}