import { Box, Stack, Typography } from "@mui/joy";
import {
  CompareWithCounseling,
  FeatureCarousel,
  OutcomeOverview,
  PaymentButton,
  QnA,
  ReviewCarousel,
  Supporters,
} from "components/task/payment/elements";
import ContentTemplate from "components/task/content/ContentTemplate";
import FloatingArea from "components/common/FloatingArea";
import TypographyWithHighlight from "components/common/TypographyWithHighlight";
import React, { useEffect, useRef, useState } from "react";
import { SxProps } from "@mui/joy/styles/types";
import paymentThumbnail from "static/image/payment/paymentThumbnail.png";
import guaranteeMark from "static/image/onboarding/guarantee_mark.png";
import useGetDeviceInfo from "hooks/useGetDeviceInfo";
import SubscriptionPlanOnly3Month from "./elements/SubscriptionPlanOnly3Month";
import Link from "components/common/Link";
import useGetUrls from "hooks/useGetUrls";

export default function PaymentContent3Month() {
  const floatingAreaRef = useRef<HTMLDivElement>(null);

  const { privacyPolicyUrl, servicePolicyUrl } = useGetUrls();

  useEffect(() => {
    console.log(floatingAreaRef.current?.offsetHeight);
  }, [floatingAreaRef]);

  const Title = ({ title }: { title: string }) => {
    return (
      <Typography
        level={"h2"}
        sx={{
          fontWeight: 700,
        }}
      >
        {title}
      </Typography>
    );
  };

  const Container = ({
    children,
    title,
    customSx,
    backgroundColor,
  }: {
    children: React.ReactNode;
    title?: string;
    customSx?: SxProps;
    backgroundColor?: string;
  }) => {
    return (
      <Stack sx={{ pt: "50px", pb: "50px", backgroundColor: backgroundColor }}>
        <Stack
          spacing={"30px"}
          sx={{ width: "100%", maxWidth: "720px", mx: "auto" }}
        >
          {title && (
            <Stack sx={{ px: "40px" }}>
              <Title title={title} />
            </Stack>
          )}
          <Stack sx={{ px: "40px", ...customSx }}>{children}</Stack>
        </Stack>
      </Stack>
    );
  };
  const { homeIndicatorHeight } = useGetDeviceInfo();
  const [containerHeight, setContainerHeight] = useState<number>(
    window.innerHeight - homeIndicatorHeight
  );
  const [floatingAreaHeight, setFloatingAreaHeight] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      setFloatingAreaHeight(floatingAreaRef.current?.offsetHeight || 0);
    }, 100);
  }, [floatingAreaRef.current]);

  return (
    <ContentTemplate noPadding isWide setContainerHeight={setContainerHeight}>
      {containerHeight > 0 && (
        <Stack sx={{ pb: "150px", opacity: floatingAreaHeight > 0 ? 1 : 0 }}>
          <Stack
            spacing={"30px"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              width: "100%",
              maxWidth: "720px",
              mx: "auto",
              px: "40px",
              // height: `calc(${containerHeight}px - ${floatingAreaHeight}px)`,
              // pt: "50px",
            }}
          >
            <img
              src={guaranteeMark}
              alt={"empty"}
              style={{
                width: "100%",
                maxWidth: "150px",
                paddingTop: "40px",
                // objectFit: "cover",
                // objectPosition: "50% 0%",
              }}
            />

            <Stack spacing={"20px"} sx={{ textAlign: "center" }}>
              <Typography
                level={"h1"}
                sx={{
                  fontSize: "32px",
                  wordBreak: "keep-all",
                  overflowWrap: "break-word",
                }}
              >
                {"효과가 없으면\n100% 돌려드립니다"}
              </Typography>
              <TypographyWithHighlight
                text={
                  "프로그램의 효과가 안정적으로 정착되기까지\n" +
                  "최소 3개월의 시간을 권장합니다.\n\n" +
                  "3개월 이용 후에도 결과에 만족하지 않으신다면\n" +
                  "지불하신 비용 100% 전액을 돌려드리겠습니다.\n\n" +
                  "support@orwellhealth.org로 “환불 요청”\n" +
                  "네 글자만 보내주세요."
                }
                highlightedText={"지불하신 비용 100% 전액"}
                type={"neutral"}
                customSx={{
                  wordBreak: "keep-all",
                  overflowWrap: "break-word",
                }}
              />
            </Stack>
            <SubscriptionPlanOnly3Month />
            <img
              src={paymentThumbnail}
              alt={"empty"}
              style={{
                width: "100%",
                maxWidth: "300px",
                paddingBottom: "60px",
                // objectFit: "cover",
                // objectPosition: "50% 0%",
              }}
            />
          </Stack>
          <Container title={"보장된 효과"} backgroundColor="white">
            <Stack spacing="30px">
              <Supporters />
              <OutcomeOverview />
              <Box sx={{ pt: "50px" }}>
                <ReviewCarousel />
              </Box>
            </Stack>
          </Container>
          <Container title={"매일, 코치와, 꾸준히"} customSx={{ px: "0px" }}>
            <FeatureCarousel />
          </Container>
          <Container title={"3배 더 많이, 4배 더 싸게"}>
            <Stack spacing="30px">
              {[
                {
                  title: "분량",
                  text: "디스턴싱에서는 매일 30분 정도의 활동이 제공돼요. 한 달로 치면 약 600분에 달하는 시간이죠. 일반 심리상담의 3배 분량이랍니다.",
                  highlightedText: "일반 심리상담의 3배",
                },
                {
                  title: "비용",
                  text: "디스턴싱은 3개월 29만 5천 원에 이용할 수 있어요. 반면, 일반 심리치료는 세 달에 120만 원 정도의 비용이 발생하죠.",
                  highlightedText: "3개월 29만 5천 원",
                },
              ].map(({ title, text, highlightedText }) => (
                <Stack
                  spacing="24px"
                  sx={{
                    borderRadius: "12px",
                    // p: "24px",
                    // backgroundColor: "white",
                    // borderWidth: "1px",
                    // borderColor: "divider",
                  }}
                >
                  <Stack spacing="12px">
                    <Typography level="body-md" fontWeight={700}>
                      {title}
                    </Typography>
                    <Stack spacing="20px">
                      <TypographyWithHighlight
                        text={text}
                        highlightedText={highlightedText}
                        type="neutral"
                      />
                      <CompareWithCounseling
                        type={title === "분량" ? "quantity" : "cost"}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Container>

          <Container>
            <Stack
              spacing={"32px"}
              sx={{ width: "100%", maxWidth: "720px", mx: "auto" }}
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={guaranteeMark}
                alt={"empty"}
                style={{
                  width: "100%",
                  maxWidth: "150px",
                  // objectFit: "cover",
                  // objectPosition: "50% 0%",
                }}
              />
              <Stack spacing={"24px"} sx={{ textAlign: "center" }}>
                <Typography
                  level={"h1"}
                  sx={{
                    wordBreak: "keep-all",
                    overflowWrap: "break-word",
                  }}
                >
                  {"잃을 것 없는\n개런티 프로그램"}
                </Typography>
                <TypographyWithHighlight
                  text={
                    "프로그램의 효과가 안정적으로 정착되기까지\n" +
                    "최소 3개월의 시간을 권장합니다.\n\n" +
                    "3개월 이용 후에도 결과에 만족하지 않으신다면\n" +
                    "지불하신 비용 100% 전액을 돌려드리겠습니다.\n\n" +
                    "시도해서 잃을 것은 없습니다.\n" +
                    "시도하지 않으면 변화의 기회를 잃을 뿐입니다."
                  }
                  highlightedText={"지불하신 비용 100% 전액"}
                  type="neutral"
                  customSx={{
                    wordBreak: "keep-all",
                    overflowWrap: "break-word",
                  }}
                />
              </Stack>
            </Stack>
          </Container>

          <Container title={"자주 묻는 질문"}>
            <QnA />
          </Container>
          <Box>
            <FloatingArea ref={floatingAreaRef}>
              <Stack spacing="8px" sx={{ width: "100%" }} alignItems="center">
                <Stack
                  spacing="12px"
                  sx={{ width: "100%" }}
                  alignItems="center"
                >
                  <Typography>
                    <Typography
                      level="body-sm"
                      sx={{ fontWeight: 600, opacity: 0.5 }}
                    >
                      {`295,000원 / 3개월`}
                    </Typography>
                    <Typography
                      level="body-sm"
                      color="primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {` (효과 보장)`}
                    </Typography>
                  </Typography>
                  <PaymentButton />
                  {/*<Link*/}
                  {/*  text={"구매 복원"}*/}
                  {/*  onClick={() => {*/}
                  {/*    alert(*/}
                  {/*      "복원할 구매 내역이 없습니다.\n관련 문의는 support@orwellhealth.org에 남겨주세요."*/}
                  {/*    );*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <Stack
                    direction={"row"}
                    spacing={1}
                    sx={{ pt: 1, wordBreak: "keep-all" }}
                  >
                    <Link text={"개인정보 처리방침"} url={privacyPolicyUrl} />
                    <Link text={"서비스 이용약관"} url={servicePolicyUrl} />
                  </Stack>
                </Stack>
              </Stack>
            </FloatingArea>
          </Box>
        </Stack>
      )}
    </ContentTemplate>
  );
}