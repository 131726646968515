import { IconButton } from "@mui/joy";
import useGoBack from "hooks/useGoBack";

export default function BackButton({
  onBack,
}: {
  onBack?: () => void; // 뒤로가기 버튼 클릭 시 실행할 함수
}) {
  return (
    <IconButton
      onClick={onBack}
      variant="plain"
      color="neutral"
      size="sm"
      sx={{
        "&:hover": { backgroundColor: "transparent" },
        "&:active": { backgroundColor: "transparent" },
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 5.5L5.5 12L12 18.5"
          stroke="#2E2B2B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconButton>
  );
}
