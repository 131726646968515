import React, { useEffect, useRef } from 'react'
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import program_start from "static/image/onboarding/program_start.svg";
import { useMutation } from '@tanstack/react-query'
import { onboardingApi } from '../../../../api/onboardingApi'

export default function Acquisition_3() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/goal/1`);
  };

  const pageKey = "acquisition_3";

  const { mutate: savePage } = useMutation(
    (pageId: string) => onboardingApi.savePage(pageId),
    {
      onSuccess: () => {},
    }
  );

  function useEffectOnce(callback: () => void) {
    const ref = useRef(false);

    useEffect(() => {
      if (ref.current) return;
      ref.current = true;

      if (typeof callback === "function") {
        callback();
      }
    }, []);
  }

  useEffectOnce(() => {
    savePage(pageKey);
  });

  return (
    <Box sx={{ paddingBottom: "120px" }}>
      <Stack
        spacing={1.5}
        sx={{ height:"100%", flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={700}
          sx={{
            color: theme.vars.palette.Content.Highlight,
          }}
        >
          잘 찾아오셨어요.
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          디스턴싱은 이해하고 훈련하는 멘탈케어 프로그램입니다.
        </CustomTypography>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(80vh - 220px)", // TODO 경험치
          }}
        >
          <img
            src={program_start}
            alt={"onboarding"}
            style={{
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
        <CustomTypography
          variant="Caption"
          fontWeight={500}
          sx={{
            textAlign: "center",
            color: theme.vars.palette.Content.Medium,
          }}
        >
          {"디스턴싱은 현대 정신의학 이론을 집대성하여\n" +
            "행동과학 중심으로 재구성한 전세계 유일의\n" +
            "근거 기반 인지행동치료 프로그램입니다."}
        </CustomTypography>
      </Stack>
      <BottomNextButton
        disabled={false}
        onClick={handleNext}
        text={"프로그램 구성하기"}
        highlightBackground={true}
      />
    </Box>
  );
}