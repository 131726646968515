import { Box } from "@mui/joy";
import React, { useEffect } from "react";
import { theme } from "styles/theme";

interface ProgressBarProps {
  progress: number; // 0~100
}

export default function ProgressBarOnboarding({ progress }: ProgressBarProps) {
  // 원형(12px + border(2px*2)=16px)이므로 반지름 = 8px
  // 내장된 원형 마커가 “가운데”까지 자연스럽게 채워지도록 하는 오프셋
  const circleRadiusOffset = 8;

  // 실제 바 너비 = progress% + 8px 을 0%~100% 범위로 clamp
  const barWidth = `clamp(0%, calc(${progress}% + ${circleRadiusOffset}px), 100%)`;

  // 진행된 마커 개수(5개 중 몇 번째까지 색칠인지) - 사용 예시
  const progressMarker = (progress * 4) / 100;

  useEffect(() => {
    console.log(`progress: ${progress}`);
  }, [progress]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "center",
        width: "100%",
        height: "12px",
        backgroundColor: theme.vars.palette.Surface.Backward,
        borderRadius: "100px",
      }}
    >
      {/* 진행 바 */}
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
          height: "12px",
          // 이하 핵심: width를 “progress% + 8px” 하되 100%를 넘지 않도록 clamp
          width: barWidth,
          backgroundColor: theme.vars.palette.Surface.Highlight,
          borderRadius: "100px",
          transition: "width 0.3s ease",
          zIndex: 0,
        }}
      />

      {/* 동그라미(마커) 5개 */}
      {Array.from({ length: 5 }).map((_, index) => (
        <Box
          key={index}
          sx={{
            // 바보다 위에 그려지도록
            zIndex: 1,
            width: "12px",
            height: "12px",
            backgroundColor: "white",
            borderRadius: "50%",
            boxSizing: "border-box",
            border: "2px solid",
            // progressMarker까지는 파란색 border, 아니면 회색 border
            borderColor:
              index <= progressMarker
                ? theme.vars.palette.Surface.Highlight
                : theme.vars.palette.Surface.Backward,
          }}
        />
      ))}
    </Box>
  );
}