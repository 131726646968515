import { axiosInstance, CustomResponseFormat } from "api/instance";

export const ONBOARDING_DOMAIN = "onboarding";

export const ONBOARDING_PAGE_SAVE_ENDPOINT = "page/save";
export const ONBOARDING_LAST_PAGE_LOAD_ENDPOINT = "page-last/load";

export interface OnboardingPageId {
  pageId?: string;
}

export const onboardingApi = {
  savePage: (pageId: string) =>
    axiosInstance.post<void>(
      `${ONBOARDING_DOMAIN}/${ONBOARDING_PAGE_SAVE_ENDPOINT}`,
      { pageId: pageId }
    ),

  loadLastPage: () =>
    axiosInstance.get<CustomResponseFormat<OnboardingPageId>>(
      `${ONBOARDING_DOMAIN}/${ONBOARDING_LAST_PAGE_LOAD_ENDPOINT}`
    ),
};