import { Stack } from "@mui/joy";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SelectButtonCircleV2 } from "components/common/SelectButtonCircleV2";
import { SelfCheckQuestion } from "components/common/SelfCheckQuestion";
import { useOnboardingData } from "hooks/useOnboardingData";

export interface QnaAnswerOption {
  id: number;
  text: string;
}

export interface Question {
  questionId: number;
  main: string;
  left: string;
  right: string;
}

const questionList: Question[] = [
  {
    questionId: 1,
    main: "내가 원하는 것은",
    left: "평온한\n" + "마음이다",
    right: "건강한\n" + "라이프스타일이다",
  },
  {
    questionId: 2,
    main: "마음 관리 기술을 배우는 것은",
    left: "삶 전반에\n" + "영향을 준다",
    right: "일부에만\n" + "영향을 준다",
  },
  {
    questionId: 3,
    main: "내 마음이 안정적이라면",
    left: "스스로를 더\n" + "사랑할 것이다",
    right: "그와 무관하게\n" + "사랑할 것이다",
  },
  {
    questionId: 4,
    main: "내가 겪는 심리적 괴로움은",
    left: "항상\n" + "내 곁에 있다",
    right: "특정한 트리거로\n" + "발생한다",
  },
  {
    questionId: 5,
    main: "내 마음을 힘들게 하는 것은",
    left: "외적인\n" + "요인 때문이다",
    right: "내적인\n" + "요인 때문이다",
  },
  {
    questionId: 6,
    main: "건강한 마음을 갖는 것은",
    left: "삶의 궁극적인\n" + "목표다",
    right: "삶의 여러\n" + "목표 중 하나다",
  },
  {
    questionId: 7,
    main: "마음을 관리하고자 하는 나의 의지는",
    left: "항상 일정하게\n" + "유지된다",
    right: "시간이 흐르며\n" + "들쑥날쑥하다",
  },
  {
    questionId: 8,
    main: "마음이 건강하다는 것은",
    left: "고통을 잘\n" + "이겨낸다는 것이다",
    right: "고통이\n" + "없다는 것이다",
  },
  {
    questionId: 9,
    main: "멘탈케어 프로그램은 내게",
    left: "새로운 것을\n" + "알려줄 것이다",
    right: "아무것도 알려주지\n" + "못할 것이다",
  },
  {
    questionId: 10,
    main: "마음을 다스리는 법을 배우는 것은",
    left: "건강한 삶을 위해\n" + "필요하다",
    right: "이미 방법을 아니\n" + "필요가 없다",
  },
];

// 옵션 리스트
const options: QnaAnswerOption[] = [
  { id: 1, text: "매우 동의" },
  { id: 2, text: "동의" },
  { id: 3, text: "동의" },
  { id: 4, text: "매우 동의" },
];

export default function Convention_2_QnA() {
  const [searchParams] = useSearchParams();
  const questionId = parseInt(searchParams.get("q") || "1");

  const navigate = useNavigate();

  const pageKey = `convention_2`; // 페이지를 구분할 키 값
  const { qaList, updateQaList } = useOnboardingData(pageKey);

  useEffect(() => {
    console.log(qaList);
  }, [qaList]);

  const handleClick = (questionId: number, answerId: number) => {
    updateQaList(questionId, answerId);

    setTimeout(() => {
      if (questionId < 10) {
        // TODO 리팩토링
        navigate(`/onboarding_new/convention/2?q=${questionId + 1}`);
      } else {
        navigate(`/onboarding_new/convention/3`);
      }
    }, 400);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "85%",
        py: "20px",
        px: "24px",
        mx: "auto",
      }}
      justifyContent="center"
    >
      <Stack
        sx={{ flex: 1 }}
        justifyContent="center"
        alignItems={"center"}
        spacing={2}
      >
        <SelfCheckQuestion question={questionList[questionId - 1]} />
        <Stack
          direction="row"
          sx={{ width: "100%" }}
          justifyContent={"space-between"}
        >
          {options.map((option, optionIndex) => (
            <SelectButtonCircleV2
              questionId={questionId}
              selected={
                qaList.find((qa) => qa.questionId === questionId)?.answerId ===
                option.id
              }
              disable={false}
              option={option}
              handleClick={handleClick}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}