import { Typography } from "@mui/joy";

import { sendMessage } from "utils/webview";
import useIsInappWebview from "hooks/useIsInappWebview";
import React from "react";
import { SxProps } from "@mui/joy/styles/types";
import { theme } from "styles/theme";

export default function LinkV2({
  text,
  url,
  variant,
  startDecorator,
  endDecorator,
  noUnderline,
  onClick,
  disabled,
  customSx,
  linkOpenType = "modal",
}: {
  text: string;
  url?: string;
  variant?: "Caption";
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  noUnderline?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  customSx?: SxProps;
  linkOpenType?: "modal" | "link";
}) {
  const isInappWebview = useIsInappWebview();

  return (
    <Typography
      textColor={theme.vars.palette.Content.Weak}
      variant={variant}
      textAlign={"center"}
      sx={{
        ...(!noUnderline && { textDecoration: undefined }),

        ...(!disabled && { cursor: "pointer" }),
        fontWeight: 500,
        whiteSpace: "nowrap",
        ...customSx,
      }}
      startDecorator={startDecorator}
      endDecorator={endDecorator}
      onClick={() => {
        if (url) {
          if (isInappWebview) {
            sendMessage(
              JSON.stringify({
                event: linkOpenType === "modal" ? "open_modal" : "open_link",
                params: {
                  url: url,
                },
              })
            );
          } else {
            window.open(url);
          }
        }
        if (onClick && !disabled) {
          onClick();
        }
      }}
    >
      {text}
    </Typography>
  );
}