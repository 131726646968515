import React from "react";
import { useNavigate } from "react-router-dom";
import FullPageWithHeaderAndProgress from "pages/container/FullPageWithHeaderAndProgress";
import { AcquisitionEtcDetail } from "../part/acquisition/AcquisitionEtcDetail";

export default function EtcWhere() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/onboarding_new/acquisition/1`);
  };

  return (
    <FullPageWithHeaderAndProgress
      headerTitle={undefined}
      hideProgress={true}
      body={<AcquisitionEtcDetail />}
      onBack={handleBack} // 첫 번째 단계에서는 뒤로가기 버튼 숨김
    />
  );
}