// CustomTypography.tsx
import React from 'react'
import { Typography, TypographyProps } from '@mui/joy'

export default function CustomTypography({
  children,
  sx,
  ...rest
}: TypographyProps) {
  return (
    <Typography
      sx={{
        py:0,
        // [공통 적용] 한글 줄바꿈을 위한 CSS 속성
        whiteSpace: "pre-wrap",
        wordBreak: "keep-all",
        overflowWrap: "break-word",
        lineBreak: "strict",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}
