import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/joy";

interface KeyboardAwareBottomButtonProps {
  disabled: boolean;
  onClick: () => void;
  text: string;
  highlightBackground?: boolean;
  loading: boolean;
}

export default function KeyboardAwareBottomButton({
  disabled,
  onClick,
  text,
  highlightBackground,
  loading,
}: KeyboardAwareBottomButtonProps) {
  // 키보드가 열릴 때 버튼을 얼마만큼 위로 띄울지 (px)
  const [bottomOffset, setBottomOffset] = useState<number>(0);

  const theme = useTheme();

  useEffect(() => {
    // visualViewport가 존재하면 이벤트 리스너 등록
    if (typeof window !== "undefined" && "visualViewport" in window) {
      const onResize = () => {
        // Keyboard가 올라오면 visualViewport.height가 줄어듦
        // offsetTop도 달라질 수 있음(iOS는 offsetTop이 0이 아닐 때가 있음)
        const viewport = window.visualViewport;
        if (!viewport) return;

        // “기본 뷰포트 높이 - 실제 보이는 뷰포트 높이 - offsetTop”을 계산
        // 이 값이 0보다 크면, 그만큼 버튼을 위로 띄움 (키보드 높이만큼 띄운다고 생각하면 됨)
        const offset =
          window.innerHeight - viewport.height - viewport.offsetTop;
        setBottomOffset(offset > 0 ? offset : 0);
      };

      window.visualViewport!!.addEventListener("resize", onResize);
      onResize(); // 초기 실행 (이미 키보드 열려있는 상황 고려)
      return () => {
        window.visualViewport!!.removeEventListener("resize", onResize);
      };
    }
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        right: 0,
        // 계산된 bottomOffset을 bottom에 적용
        bottom: `${bottomOffset}px`,
        zIndex: 1200,
        // 키보드 아래에 깔리지 않도록 높은 zIndex
        // 필요한 경우 배경색 그Radient를 넣거나 etc.
        background: !highlightBackground
          ? `linear-gradient(180deg, rgba(246, 249, 250, 0) 0%, ${theme.vars.palette.Background.Level1} 20%)`
          : `linear-gradient(180deg, rgba(220, 239, 250, 0) 0%, ${theme.vars.palette.Background.Level1} 20%)`,

        // 하단 여백 + 패딩
        p: 2,
        display: 'flex', // flexbox 사용
        justifyContent: 'center', // 가로 가운데 정렬
      }}
    >
      <Button
        loading={loading}
        variant="Action"
        sx={{
          maxWidth: "680px",
          width: "100%",
          height: "54px",
          fontWeight: 700,
          borderRadius: "12px",
          fontSize: "16px",
          lineHeight: "132%",
        }}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </Button>
    </Box>
  );
}