import { useRecoilState } from "recoil";
import { onboardingProgressState } from "recoil/onboardingProgressAtom";

export function useOnboardingProgress() {
  const [progress, setProgress] = useRecoilState(onboardingProgressState);

  return {
    progress,
    setProgress,
  };
}