import React from "react";
import { Box, Typography } from "@mui/joy";
import { Grid } from "@mui/material";
import { Question } from "pages/onboarding/part/convention/Convention_2_QnA";
import { theme } from "styles/theme";
import versus from "static/image/onboarding/versus.svg";
import CustomTypography from "./CustomTypography";

export function SelfCheckQuestion({ question }: { question: Question }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%", // 필요시 100% 너비로
      }}
    >
      <CustomTypography
        variant="H3"
        sx={{
          color: theme.vars.palette.Content.Strong,
          fontWeight: 500,
          mb: 2,
          whiteSpace: "nowrap", // ← 자동 줄바꿈 방지
          overflow: "hidden", // 화면 넘어갈 경우 숨기거나
        }}
      >
        {question.main}
      </CustomTypography>

      {/* 3등분: left - center(versus) - right */}
      <Grid container columns={12} alignItems="center">
        {/* 왼쪽 */}
        <Grid item xs={5}>
          <Box textAlign="center">
            <Typography
              variant="Subtitle"
              sx={{
                color: theme.vars.palette.Content.Strong,
                fontWeight: 700,
                whiteSpace: "pre",
              }}
            >
              {question.left}
            </Typography>
          </Box>
        </Grid>

        {/* 가운데(versus) */}
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center">
            <img
              src={versus}
              alt="onboarding"
              style={{
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        </Grid>

        {/* 오른쪽 */}
        <Grid item xs={5}>
          <Box textAlign="center">
            <Typography
              variant="Subtitle"
              sx={{
                fontWeight: 700,
                color: theme.vars.palette.Content.Strong,
                whiteSpace: "pre",
              }}
            >
              {question.right}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}