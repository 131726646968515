export const PATH = {
  login: "/login",
  redirect: "/oauth/kakao",
  redirect_apple: "/oauth/apple",
  onboarding: "/onboarding/:id",
  onboarding_new: "/onboarding_new/:type/:id",
  etc_where: "/onboarding_new/etc_where",
  onboardingBeforeLogin: "/onboarding/guest/:id",

  home: "/:taskKey?",
  survey: "/survey",
  payment: "/payment",
  paymentV2: "/v2/payment",

  tossPayments: "/toss-payments",
  tossPaymentsSuccess: "/toss-payments/success",
  tossPaymentsFail: "/toss-payments/fail",

  newsletterSettings: "/newsletter/settings",

  selfCheck: "/self-check/:tool/:id",
  selfCheckEnd: "/self-check/:tool/end",

  steppayPaymentSuccess: "/payment/steppay/success",
  steppayPaymentFail: "/payment/steppay/fail",
  steppayManagement: "/payment/management",

  institutionSelect: "/institution-select",
  emailAuth: "/email-auth",
  emailAuthResult: "email-auth/result",

  tool: "/tool/:step",
  meditationTool: "/meditation",
  thoughtRecordTool: "/thought-record",
  thoughtRecordTool_recording: "/thought-record/recording",
  thoughtRecordTool_isolation: "/thought-record/:ttr",
  thoughtRecordTool_thoughtTrap: "/thought-record/thought-trap",
  test: "/test",
  testSpeechToText: "/demo-stt",
};
