import { Box, Button, Stack, Typography } from "@mui/joy";
import { theme } from "styles/theme";
import React, { useState } from "react";
import useDetectIOS from "hooks/useDetectIOS";
import { useMutation } from "@tanstack/react-query";
import { acquisitionApi } from "api/acquisitionApi";
import { useNavigate } from "react-router-dom";
import CustomTypography from '../../../../components/common/CustomTypography'

export function Acquisition_1() {
  const { isIOS } = useDetectIOS();
  const navigate = useNavigate();

  const [selectedChannel, setSelectedChannel] = useState<string>();

  const [isAnimating, setIsAnimating] = useState(false); // 애니메이션 상태 추가

  const channelList = [
    { name: "브런치" },
    { name: "네이버 카페" },
    { name: "네이버 블로그" },
    { name: "디스턴싱 오픈카톡방" },
    { name: "디스턴싱 홈페이지" },
    { name: "인스타그램" },
    { name: "쓰레드" },
    { name: "트위터" },
    { name: `${isIOS ? "앱 스토어" : "플레이 스토어"}에서 둘러보다` },
    { name: "누군가의 추천" },
    { name: "기타" },
  ];

  const { mutate: submit } = useMutation(
    (name: string) => acquisitionApi.submit(name),
    {
      onSuccess: () => {
        navigate("/onboarding_new/acquisition/3");
      },
    }
  );

  function handleSelected(name: string) {
    setSelectedChannel(name);

    setTimeout(() => {
      setIsAnimating(false); // 애니메이션 종료

      if (name === "기타") {
        navigate("/onboarding_new/etc_where");
      } else {
        submit(name);
      }
    }, 100);
  }

  return (
    <Stack spacing={1.5} sx={{ paddingBottom: "60px" }}>
      <CustomTypography
        variant="Subtitle"
        fontWeight={700}
        sx={{ color: theme.vars.palette.Content.Highlight }}
      >
        디스턴싱에 오신 것을 환영합니다.
      </CustomTypography>
      <CustomTypography
        variant="Subtitle"
        fontWeight={500}
        sx={{
          color: theme.vars.palette.Content.Strong,
        }}
      >
        무엇을 보고 디스턴싱을 시작하려 결심하셨나요?
      </CustomTypography>

      <Box sx={{ pt: 3, display: "flex", flexDirection: "column" }}>
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            whiteSpace: "nowrap",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          {channelList.map(({ name }, optionIndex) => (
            <Stack key={`channel_option_${name}`} sx={{ flexGrow: 1, gap: 1 }}>
              <Button
                fullWidth
                variant={"SingleSlim"}
                color={selectedChannel === name ? "primary" : "secondary"}
                sx={{
                  height: "54px",
                  borderRadius: "12px",
                  fontSize: "Body",
                  transition: "background-color 0.3s ease", // ease 애니메이션 추가
                }}
                value={name}
                onClick={(e) => handleSelected(name)}
              >
                {name}
              </Button>
            </Stack>
          ))}
        </Stack>
      </Box>
      <Box
        sx={{
          // 화면 하단에 고정
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          zIndex: 1200,
          background: `linear-gradient(180deg, rgba(246, 249, 250, 0) 0%, ${theme.vars.palette.Background.Level1} 50%)`,
          // TODO home indicator 있는 경우 고려
          p: 2, // 16px
        }}
      ></Box>
    </Stack>
  );
}