import React, { useEffect, useState } from 'react'
import { Box } from '@mui/joy'
import { theme } from 'styles/theme'
import CustomTypography from 'components/common/CustomTypography'
import { useNavigate } from 'react-router-dom'
import result_main_logo from 'static/image/onboarding/result_main_logo.svg'

/*
  ① 2초간 텍스트 보여주고,
  ② 1초 동안 텍스트 fade-out + 로고 fade-in,
  ③ 1초 뒤 handleNext()

  (수치는 취향대로 조절 가능)
*/

export default function Result_2() {
  const navigate = useNavigate();
  const [showText, setShowText] = useState(true); // 텍스트
  const [showLogo, setShowLogo] = useState(false); // 로고

  useEffect(() => {
    // 3초 뒤 텍스트를 fade-out, 로고를 fade-in
    const timer1 = setTimeout(() => {
      setShowText(false);
      setShowLogo(true);
    }, 2000);

    // 전체 5초 쯤 지난 후 handleNext() → 다음 화면으로 넘어가는 로직
    const timer2 = setTimeout(() => {
      handleNext();
    }, 4000);

    // 언마운트 시 타이머 정리
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  const handleNext = () => {
    navigate(`/onboarding_new/result/3`);
  };

  return (
    <Box
      sx={{
        // 겹치는 두 개(텍스트 / 로고)를 같은 위치에 쌓아두기
        position: "relative",
        width: "100%",
        minHeight: "200px", // 예시용
      }}
    >
      {/* ➊ 텍스트(초반에 보임, 서서히 사라짐) */}
      <Box
        sx={{
          position: "absolute",
          inset: 0, // (top=0, right=0, bottom=0, left=0)
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 1s", // 1초간 부드러운 fade
          opacity: showText ? 1 : 0,
        }}
      >
        <CustomTypography
          variant="H3"
          fontWeight={500}
          sx={{
            textAlign: "center",
            whiteSpace: "pre-line", // \n 줄바꿈
            color: theme.vars.palette.Content.Negative,
          }}
        >
          {"효과가 입증된\n지속가능한 멘탈케어"}
        </CustomTypography>
      </Box>

      {/* ➋ 로고(초반에 안보이다가 텍스트가 사라질 때부터 보임) */}
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 1s",
          opacity: showLogo ? 1 : 0,
        }}
      >
        {/* 실제 로고 이미지나 컴포넌트를 여기에 배치 */}
        <img
          src={result_main_logo}
          alt="메인 로고"
          style={{
            width: "245px",
            height: "auto",
          }}
        />
      </Box>
    </Box>
  );
}
