import { Stack } from "@mui/joy";
import React, { useEffect, useRef } from "react";
import FullPageContainer from "./FullPageContainer";
import useIsInappWebview from "hooks/useIsInappWebview";
import { sendMessage } from "utils/webview";
import { theme } from "styles/theme";
import { extractHexColor } from "../../styles/hexOpacity";

export default function FullPageWithCenterContentV2({
  backgroundColor = theme.vars.palette.Background.Level1,
  content,
}: {
  backgroundColor?: string;
  content: React.ReactNode;
}) {
  const isInappWebview = useIsInappWebview();

  const containerRef = useRef<HTMLDivElement>(null);

  // 화면 잘림 문제
  useEffect(() => {
    // 페이지 전환(혹은 특정 이벤트) 시점에
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [console]);

  useEffect(() => {
    if (isInappWebview) {
      const hexValue = extractHexColor(backgroundColor);

      if (hexValue) {
        sendMessage(
          JSON.stringify({
            event: "set_safearea_color",
            params: {
              color: `${hexValue}`,
            },
          })
        );
      }
    }
  }, [backgroundColor]);

  return (
    <FullPageContainer hasFooter={false} backgroundColor={backgroundColor}>
      <Stack
        ref={containerRef}
        flexGrow={1}
        justifyContent="center"
        sx={{ width: "100%", maxWidth: "500px", mx: "auto", px: "20px" }}
      >
        {content}
      </Stack>
    </FullPageContainer>
  );
}