import React from "react";
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import ButtonProgress from "components/common/BottonProgress";

export default function Habit_3() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/habit/4`);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "70vh" }}>
      <Stack
        spacing={1.5}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={700}
          sx={{
            color: theme.vars.palette.Content.Highlight,
          }}
        >
          {"원리를 알아야 합니다."}
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "우리 마음이 동작하는 원리를 모르면 문제는 계속 되풀이 될 뿐입니다."
          }
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "피하거나, 덮어버리거나, 없애는 방식으로는 결코 심리적 괴로움에서 벗어날 수 없습니다."
          }
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "마음 문제는 다르게 접근해야 합니다."
          }
        </CustomTypography>
      </Stack>
      <ButtonProgress handleNext={handleNext} />
    </Box>
  );
}