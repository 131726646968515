import { Button, Stack } from "@mui/joy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { sendMessage } from "utils/webview";
import useIsInappWebview from "hooks/useIsInappWebview";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import useGetEventFromApp from "hooks/useGetEventFromApp";
import { useEffect, useState } from "react";
import { USER_DETAIL_ENDPOINT, USER_DOMAIN, userApi } from "api/userApi";
import ButtonWithModal from "components/common/ButtonWithModal";
import { deepLinkData } from "data/deepLinkData";
import { isBrowser } from "react-device-detect";
import { paymentOrderAtom } from "recoil/paymentOrderAtom";
import { useTranslation } from "react-i18next";
import { PATH } from "routes/paths";
import { useNavigate } from "react-router-dom";

export default function PaymentButton({
  color = "primary",
  size = "lg",
}: {
  color?: "primary" | "white";
  size?: "sm" | "lg";
}) {
  const isInappWebview = useIsInappWebview();

  const { t: t_payment } = useTranslation("translation", {
    keyPrefix: "payment",
  });

  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const paymentOrderState = useRecoilValue(paymentOrderAtom);
  const parsedMessageFromApp = useGetEventFromApp();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false); //중복 방지

  useEffect(() => {
    if (parsedMessageFromApp?.event === "payment") {
      const status = parsedMessageFromApp.params.status;
      if (status) {
        setIsLoading(false);
        if (status === "success" && !isDone) {
          queryClient.invalidateQueries([USER_DOMAIN, USER_DETAIL_ENDPOINT]);
          sendMessage(
            JSON.stringify({
              event: "navigate_tab",
              params: {
                tabName: "코칭",
              },
            })
          );
          setIsDone(true);
        }
      }
    }
  }, [parsedMessageFromApp]);

  const { isNotStarted, hasFreeTrialUsed } = useSubscriptionStatus();
  const buttonText = isNotStarted
    ? paymentOrderState?.paymentProvider === "toss_payments"
      ? "프로그램 시작하기"
      : "구독 시작하기"
    : "계속 이용하기";

  const disabled = !!user?.subscription?.isSubscribing;

  const requestPayment = () => {
    if (paymentOrderState) {
      if (paymentOrderState?.paymentProvider === "in_app") {
        if (isInappWebview) {
          if (paymentOrderState?.firstMonthOfferLinkIos) {
            sendMessage(
              JSON.stringify({
                event: "open_link",
                params: {
                  url: paymentOrderState.firstMonthOfferLinkIos,
                },
              })
            );
          } else {
            setIsLoading(true);

            sendMessage(
              JSON.stringify({
                event: "payment",
                params: {
                  productId: paymentOrderState?.productId,
                  offer: paymentOrderState?.offer,
                },
              })
            );
          }
        }
      } else if (paymentOrderState?.paymentProvider === "toss_payments") {
        if (isInappWebview) {
          setIsLoading(true);

          sendMessage(
            JSON.stringify({
              event: "request_toss_payments",
              params: {
                url: `${window.location.origin}${PATH.tossPayments}`,
              },
            })
          );

          setTimeout(() => {
            setIsLoading(false);
          }, 5000);
        } else {
          navigate("/toss-payments");
        }
      }
    }
  };

  const { mutate: reassignCoach } = useMutation(() => userApi.reassignCoach(), {
    onSuccess: () => {},
  });

  return !isInappWebview &&
    isBrowser &&
    paymentOrderState?.paymentProvider !== "toss_payments" ? (
    <ButtonWithModal
      size={size}
      color={color}
      buttonFullWidth
      buttonText={buttonText ?? ""}
      disabled={disabled}
      title={t_ui("앱에서 진행해주세요")}
      textAlign="center"
      content={
        <Stack alignItems={"center"} spacing={1}>
          <img
            src={deepLinkData.payment.qr}
            alt={"empty"}
            className="w-[100px] "
          />
        </Stack>
      }
    />
  ) : user?.isCoachChanged ? (
    <ButtonWithModal
      size={size}
      color={color}
      buttonFullWidth
      buttonText={buttonText ?? ""}
      disabled={disabled}
      loading={isLoading}
      title={"담당 코치가 변경되었어요"}
      subtitle={`${user?.assignedCoachName} 선생님은 더이상 디스턴싱과 함께하지 않게 되었어요.`}
      textAlign="center"
      actionText="새 코치 만나기"
      action={() => {
        reassignCoach();
        requestPayment();
      }}
    />
  ) : (
    <Button
      loading={isLoading}
      disabled={disabled}
      onClick={requestPayment}
      size={size}
      color={color}
      sx={{ width: "100%" }}
    >
      {buttonText}
    </Button>
  );
}