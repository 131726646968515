import React from "react";
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import goal_7_graph_1 from "static/image/onboarding/goal_7_graph_1.svg";

export default function Goal_8() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/goal/9`);
  };

  return (
    <Box sx={{ paddingBottom: "120px" }}>
      <Stack
        spacing={10}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Stack spacing={1.5}>
          <CustomTypography
            variant="Subtitle"
            fontWeight={700}
            sx={{
              color: theme.vars.palette.Content.Highlight,
            }}
          >
            {"그렇다면 우리는 왜 괴로운 걸까요?"}
          </CustomTypography>
          <CustomTypography
            variant="Subtitle"
            fontWeight={500}
            sx={{
              color: theme.vars.palette.Content.Strong,
            }}
          >
            {
              "간단합니다. ‘디스턴싱(생각과 거리두기)’을 하지 못했기 때문입니다."
            }
          </CustomTypography>
          <CustomTypography
            variant="Subtitle"
            fontWeight={500}
            sx={{
              color: theme.vars.palette.Content.Strong,
            }}
          >
            {
              "디스턴싱은 마음을 다루는 기술입니다."
            }
          </CustomTypography>
          <CustomTypography
            variant="Subtitle"
            fontWeight={500}
            sx={{
              color: theme.vars.palette.Content.Strong,
            }}
          >
            {
              "지금부터 그 어떤 고통이 찾아오더라도 삶의 중심을 지키는 법을 알려드리겠습니다."
            }
          </CustomTypography>
        </Stack>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={goal_7_graph_1}
            alt={"onboarding"}
            style={{
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Stack>
      <BottomNextButton
        disabled={false}
        onClick={handleNext}
        text={"다음"}
        highlightBackground={true}
      />
    </Box>
  );
}
