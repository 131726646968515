import React from "react";
import { Stack } from "@mui/joy";
import { theme } from "styles/theme";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import FourProgressBars from "components/common/FourProgressBars";

export default function Result_1() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/result/2`);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        py: "60px",
        mx: "auto",
      }}
      justifyContent="center"
    >
      <Stack
        spacing={"60px"}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            textAlign: "center",
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {"답변을 분석하고 있습니다.\n" + "프로그램이 곧 준비됩니다."}
        </CustomTypography>
        <FourProgressBars handleNext={handleNext} />
        <CustomTypography
        variant={"Caption"}
        fontWeight={500}
        sx={{
          textAlign: "center",
          color: theme.vars.palette.Content.Medium,
        }}
        >
          {"잠시만 기다려주세요. 이미 성공을 경험한\n디스턴싱 유저들의 데이터 포인트 15만 개에 기반해\n내게 꼭 맞는 프로그램을 구성하고 있습니다."}
        </CustomTypography>
      </Stack>
    </Stack>
  );
}