import * as _ from "lodash";
import { Button } from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { ProgramContentType } from "data/BlockComponent";
import { useTranslation } from "react-i18next";
import { setProgramContentData } from "logic/logics";
import { appLog, sendMessage } from "utils/webview";
import useGetEventFromApp from "hooks/useGetEventFromApp";

export default function AudioButtonWebview({
  taskKey,
  meditationRecordKey,
  url,
  initialTime,
  setData,
  blockIndex,
  lineIndex,
  cellIndex,
  isCoach,
  disabled,
  extraAudioUrl,
  extraLoopNumber = 0,
}: {
  taskKey?: string;
  meditationRecordKey?: string;
  url: string;
  initialTime: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  blockIndex: number;
  lineIndex: number;
  cellIndex: number;
  isCoach: boolean;
  disabled: boolean;
  extraAudioUrl?: string;
  extraFileName?: string;
  extraLoopNumber?: number;
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });
  const audioRef = useRef<HTMLAudioElement>(null);
  const extraAudioRef = useRef<HTMLAudioElement>(null);
  const [play, setPlay] = useState(false);
  const [duration, setDuration] = useState<number>(0);
  const [extraDuration, setExtraDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(initialTime);
  const currentTimeRef = useRef<number>(initialTime);
  // 재생
  const start = () => {
    if (duration > 0 && (extraAudioUrl ? extraDuration > 0 : true)) {
      const currentTime = currentTimeRef.current;
      appLog(`start\ncurrentTime:${currentTime}`);

      if (currentTime >= duration) {
        sendMessage(
          JSON.stringify({
            event: "audio",
            params: {
              src: extraAudioUrl,
              initialTime: Math.max(
                0,
                Math.floor((currentTime - duration) % extraDuration)
              ),
              playing: true,
            },
          })
        );
      } else {
        sendMessage(
          JSON.stringify({
            event: "audio",
            params: {
              src: url,
              initialTime: currentTime,
              playing: true,
            },
          })
        );
      }

      setPlay(true);
    }
  };

  // 일시 정지
  const stop = () => {
    sendMessage(
      JSON.stringify({
        event: "audio",
        params: {
          playing: false,
        },
      })
    );
    setPlay(false);
  };

  function setAudioEnded() {
    setData((data) => {
      if (data) {
        const data_temp = _.cloneDeep(data);
        const currentContent =
          data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};

        data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
          ...currentContent,
          isEnded: true,
        };
        if (!_.isEqual(data, data_temp)) {
          return data_temp;
        }
        return data;
      }
      return data;
    });
  }

  function handleEnded() {
    if (extraAudioUrl) {
      //필수 오디오 끝나고 반복 오디오
      const currentTime =
        currentTimeRef.current > duration
          ? duration + extraDuration * (extraLoopNumber + 1)
          : duration;
      setCurrentTime(currentTime);
      currentTimeRef.current = currentTime;
      appLog(`ended\n currentTime:${currentTime}`);
      setProgramContentData({
        setData,
        blockIndex,
        lineIndex,
        cellIndex,
        newlyAddedData: {
          currentTime: currentTime,
          ...(currentTime > duration && {
            extraLoopNumber: extraLoopNumber + 1,
          }),
        },
      });
      start();
    } else {
      stop();
    }

    if (!isCoach) {
      setAudioEnded();
    }
  }

  function handleDurationChange() {
    // console.log("durationChange", audioRef.current?.duration);
    if (audioRef.current?.duration) {
      setDuration(parseInt(`${audioRef.current?.duration}`));
    }
  }

  function handleDurationChange_extra() {
    // console.log("durationChange", audioRef.current?.duration);
    if (extraAudioRef.current?.duration) {
      setExtraDuration(parseInt(`${extraAudioRef.current?.duration}`));
    }
  }

  function parseSecondsToFormat(seconds: number) {
    return `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(
      2,
      "0"
    )}`;
  }

  const parsedMessageFromApp = useGetEventFromApp();
  const handleMessageFromApp = (parsedMessageFromApp: {
    event: string;
    params: any;
  }) => {
    if (parsedMessageFromApp?.event === "audio") {
      const audioSrc = parsedMessageFromApp.params.audioSrc;
      if (
        currentTime < duration ? url === audioSrc : extraAudioUrl === audioSrc
      ) {
        //isHidden인 블록들에서도 동작하는 것 막기 위함
        const currentTimeFromApp = parsedMessageFromApp.params.currentTime || 0;

        const time =
          currentTime < duration
            ? currentTimeFromApp
            : duration + extraDuration * extraLoopNumber + currentTimeFromApp;
        setCurrentTime(time);
        currentTimeRef.current = time;
        // appLog(`${duration} ${currentTimeRef.current} ${extraLoopNumber}`);
        setProgramContentData({
          setData,
          blockIndex,
          lineIndex,
          cellIndex,
          newlyAddedData: {
            currentTime: time,
            ...(time > 0 && {
              id: "meditation_time",
              value: `${Math.floor((time || 60) / 60)}`,
            }),
          },
        });
        const status = parsedMessageFromApp.params.status;

        if (status === "didJustFinish") {
          handleEnded();
        }
      }
    }
  };

  useEffect(() => {
    if (parsedMessageFromApp) {
      handleMessageFromApp(parsedMessageFromApp);
    }
  }, [parsedMessageFromApp]);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      if (!isCoach) {
        setAudioEnded();
      }
    }
  };

  useEffect(() => {
    // setProgramContentData({
    //   setData,
    //   blockIndex,
    //   lineIndex,
    //   cellIndex,
    //   newlyAddedData: { currentTime: 0, extraLoopNumber: 0 },
    // });
    document.addEventListener("visibilitychange", handleVisibilityChange);
    if (audioRef.current) {
      audioRef.current.addEventListener("durationchange", handleDurationChange);
    }
    if (extraAudioRef.current) {
      extraAudioRef.current.addEventListener(
        "durationchange",
        handleDurationChange_extra
      );
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (audioRef.current) {
        audioRef.current.removeEventListener(
          "durationchange",
          handleDurationChange
        );
      }
      if (extraAudioRef.current) {
        extraAudioRef.current.removeEventListener(
          "durationchange",
          handleDurationChange_extra
        );
      }
    };
  }, [duration, extraLoopNumber]);

  useEffect(() => {
    return () => {
      sendMessage(
        JSON.stringify({
          event: "audio",
          params: {
            unmounted: true,
          },
        })
      );
    };
  }, []);

  return (
    <div>
      <div className={`hidden`}>
        <audio ref={audioRef} src={url} controls></audio>
      </div>
      {extraAudioUrl && (
        <div className={`hidden`}>
          <audio ref={extraAudioRef} src={extraAudioUrl} controls loop></audio>
        </div>
      )}
      <Button
        disabled={disabled}
        fullWidth
        onClick={(event) => {
          if (play) {
            stop();
          } else {
            start();
          }
        }}
        color={play ? "primary" : "secondary"}
        variant={"solid"}
        size={"lg"}
      >
        {play
          ? `${parseSecondsToFormat(currentTime)} / ${parseSecondsToFormat(
              duration
            )}`
          : t_ui(
              currentTime === 0 || currentTime === duration
                ? "음성 안내 재생하기"
                : "이어서 재생하기"
            )}
      </Button>
    </div>
  );
}