import { IconButton, Stack, Typography } from "@mui/joy";
import ButtonWithModal from "components/common/ButtonWithModal";
import TypographyWithLinkSubstring from "components/common/TypographyWithLinkSubstring";
import { useTranslation } from "react-i18next";
import LinkV2 from "../common/LinkV2";
import { theme } from '../../styles/theme'

export default function CxButton({
  type = "icon",
}: {
  type?: "icon" | "link";
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  return (
    <ButtonWithModal
      buttonText={""}
      size="lg"
      title={"고객 센터"}
      textAlign="center"
      render={
        type === "link" ? (
          <LinkV2 text={"고객 센터"} />
        ) : (
          <IconButton size="sm" variant="plain" color="neutral">
            <span className="material-symbols-outlined">help</span>
          </IconButton>
        )
      }
      content={
        <Stack
          alignItems={"center"}
          spacing={3}
          sx={{ width: "100%", color: "text.tertiary" }}
        >
          <Typography variant={"Body"} sx={{ fontWeight: 500, color: theme.vars.palette.Content.Strong}}>
            <a href="mailto:support@orwellhealth.org?subject=문의드립니다&body=닉네임:%0D%0A문의사항:">
              support@orwellhealth.org
            </a>
          </Typography>
          <Typography>
            <TypographyWithLinkSubstring
              mainString={"환불 및 페이백 문의"}
              linkSubstrings={[
                {
                  linkString: "환불 및 페이백 문의",
                  url: "https://orwellhealth.notion.site/d07b7b28b8e04d858a93641249748b0f",
                },
              ]}
              linkOpenType="link"
            />
          </Typography>
        </Stack>
      }
    />
  );
}