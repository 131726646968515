import React, { useEffect, useRef } from 'react'
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import convention_coach from "static/image/onboarding/convention_coach.svg";
import { useMutation } from '@tanstack/react-query'
import { onboardingApi } from '../../../../api/onboardingApi'

export default function Convention_3() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/nickname/1`); // TODO 닉네임
  };

  const pageKey = "convention_3";

  const { mutate: savePage } = useMutation(
    (pageId: string) => onboardingApi.savePage(pageId),
    {
      onSuccess: () => {},
    }
  );

  function useEffectOnce(callback: () => void) {
    const ref = useRef(false);

    useEffect(() => {
      if (ref.current) return;
      ref.current = true;

      if (typeof callback === "function") {
        callback();
      }
    }, []);
  }

  useEffectOnce(() => {
    savePage(pageKey);
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "70vh" }}>
      <Stack
        spacing={1.5}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={700}
          sx={{
            color: theme.vars.palette.Content.Highlight,
          }}
        >
          전문 코치와 함께합니다.
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "정신의학, 심리학, 행동과학에 조예가 깊은 코치 선생님이 나의 프로그램을 이끕니다."
          }
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {
            "평일 근무시간 언제든 코치의 지원을 받을 수 있습니다."
          }
        </CustomTypography>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={convention_coach}
            alt={"onboarding"}
            style={{
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Stack>
      <BottomNextButton
        disabled={false}
        onClick={handleNext}
        text={"다음"}
        highlightBackground={true}
      />
    </Box>
  );
}