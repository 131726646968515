import { useMutation } from "@tanstack/react-query";
import { paymentApi, TossPaymentsConfirmType } from "api/paymentApi";
import { userAtom } from "../recoil/userAtom";
import { useSetRecoilState } from "recoil";
import { userApi } from "../api/userApi";

export function useConfirmTossPayments() {
  const setUser = useSetRecoilState(userAtom);

  return useMutation(
    // 토스 결제 성공 API
    async (payload: TossPaymentsConfirmType) => {
      const response = await paymentApi.confirmTossPayments(payload);
      return response;
    },
    {
      onSuccess: async (responseData, variables) => {
        // (1) 결제확인 API 성공 후, userApi.getDetail()로 최신 유저 정보 가져오기
        // try {
        //   const data = await userApi.getDetail();
        //   if (data?.result) {
        //     setUser((prev) => ({
        //       ...prev,
        //       ...data.result,
        //     }));
        //   }
        // } catch (error) {
        //   console.error("userApi.getDetail() 호출 실패:", error);
        // }
      },
      onError: (error) => {
        alert("결제가 실패했어요. 다시 시도해 주세요.");
      },
    }
  );
}