import React from "react";
import { Box } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import MultiTextTransition from "components/common/MultiTextTransition";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { useOnboardingData } from "hooks/useOnboardingData";
import { goal2OptionList } from "../goal/Goal_2_MultiSelect_1";
import { goal5OptionList } from "../goal/Goal_5_MultiSelect_3";

export default function Result_3() {
  const navigate = useNavigate();

  const user = useRecoilValue(userAtom);
  const selectionIdListGoal2 = useOnboardingData("goal_2");
  const selectionIdListGoal5 = useOnboardingData("goal_5");

  const top2Goal2List = goal2OptionList
    .filter((a) => selectionIdListGoal2.multiSelectionIdList.includes(a.id))
    .sort((a, b) => a.id - b.id)
    .slice(0, 2);

  const top2Goal5List = goal5OptionList
    .filter((a) => selectionIdListGoal5.multiSelectionIdList.includes(a.id))
    .sort((a, b) => a.id - b.id)
    .slice(0, 2);

  const goal2OptionTextList = top2Goal2List.map((a, index) => {
    if (top2Goal2List.length === 1) {
      return a.text_2;
    } else {
      // 2개
      if (index === 0) {
        return a.text_1;
      } else {
        return a.text_2;
      }
    }
  });

  const goal5OptionTextList = top2Goal5List.map((a) => a.text).join(",\n");

  if (goal2OptionTextList.length === 0) {
    console.error(`${goal2OptionTextList} 값 없음`);
    goal2OptionTextList.push(
      "평안하고 여유로운 마음을 가지는\n" + "연습을 해나갈 것입니다."
    );
  }

  function attachEulLeul(word: string): string {
    if (!word) {
      return ""; // 빈 문자열 처리
    }

    const lastChar = word.slice(-1); // 마지막 글자 추출
    const charCode = lastChar.charCodeAt(0);

    // 한글 범위 확인 (가 ~ 힣)
    if (charCode < 0xac00 || charCode > 0xd7a3) {
      return word; // 한글이 아니면 그대로 반환
    }

    const jongsungCode = (charCode - 0xac00) % 28; // 종성 코드 계산

    if (jongsungCode === 0) {
      return "를"; // 받침 없음
    } else {
      return "을"; // 받침 있음
    }
  }

  const handleNext = () => {
    navigate("/onboarding_new/result/4");
  };

  const textList = [
    `${user?.nickname}님을 위한\n맞춤 프로그램입니다.`,
    `${goal5OptionTextList}${attachEulLeul(goal5OptionTextList)}\n집중적으로 다루며`,
    ...goal2OptionTextList,
  ];

  return (
    <Box
      sx={{
        // 겹치는 두 개(텍스트 / 로고)를 같은 위치에 쌓아두기
        position: "relative",
        width: "100%",
      }}
    >
      <MultiTextTransition
        textList={textList}
        fadeInTime={1000}
        displayTime={2000}
        fadeOutTime={1000}
        onComplete={handleNext}
      />
    </Box>
  );
}