import React from "react";
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import { BottomNextButton } from "components/common/BottomNextButton";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import goal_7_graph_0 from "static/image/onboarding/goal_7_graph_0.svg";

export default function Goal_7() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/goal/8`);
  };

  return (
    <Box sx={{ paddingBottom: "120px" }}>
      <Stack
        spacing={10}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Stack spacing={1.5}>
          <CustomTypography
            variant="Subtitle"
            fontWeight={700}
            sx={{
              color: theme.vars.palette.Content.Highlight,
            }}
          >
            {"나의 잘못이 아닙니다."}
          </CustomTypography>
          <CustomTypography
            variant="Subtitle"
            fontWeight={500}
            sx={{
              color: theme.vars.palette.Content.Strong,
            }}
          >
            {"우리 모두의 삶은 저마다의 이유로 흔들립니다."}
          </CustomTypography>
          <CustomTypography
            variant="Subtitle"
            fontWeight={500}
            sx={{
              color: theme.vars.palette.Content.Strong,
            }}
          >
            {"고통은 다양한 이유로 불현듯 찾아와 우리의 마음을 괴롭게 하죠."}
          </CustomTypography>
          <CustomTypography
            variant="Subtitle"
            fontWeight={500}
            sx={{
              color: theme.vars.palette.Content.Strong,
            }}
          >
            {"내가 약하거나 부족해서가 아니랍니다."}
          </CustomTypography>
        </Stack>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={goal_7_graph_0}
            alt={"onboarding"}
            style={{
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Stack>
      <BottomNextButton
        disabled={false}
        onClick={handleNext}
        text={"다음"}
        highlightBackground={true}
      />
    </Box>
  );
}