import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useFetch from "hooks/useFetch";
import { sendMessage } from "../../utils/webview";
import { useRecoilValue } from "recoil";
import { permanentDataAtom } from "../../recoil/permanentDataAtom";
import DefaultPage from "../container/DefaultPage";
import { CircularProgress, Stack } from "@mui/joy";

export default function TossPaymentsFail() {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const orderId = searchParams.get("orderId");
  const code = searchParams.get("code");
  const message = searchParams.get("message");

  const permanentDataState = useRecoilValue(permanentDataAtom);
  const isInappWebview = permanentDataState?.isWebview;

  const [paymentFailData, paymentFail] = useFetch({
    url: `/payment/toss-payments/fail`,
    requestBody: JSON.stringify({
      orderId: orderId,
      code: code,
      message: message,
    }),
  });

  useEffect(() => {
    if (orderId) {
      paymentFail();
    }
  }, [orderId]);

  useEffect(() => {
    if (paymentFailData) {
      if (isInappWebview) {
        if (code === "PAY_PROCESS_CANCELED") {
          sendMessage(
            JSON.stringify({
              event: "toss_payments",
              params: {
                status: "cancel",
              },
            })
          );
        } else {
          sendMessage(
            JSON.stringify({
              event: "toss_payments",
              params: {
                status: "fail",
              },
            })
          );
        }
      } else {
        // TODO
        navigate("/", { replace: true });
      }
    }
  }, [paymentFailData]);

  return (
    <DefaultPage
      content={
        <Stack alignItems={"center"}>
          <CircularProgress color="neutral" />
        </Stack>
      }
    />
  );
}