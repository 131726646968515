import { useRecoilValue } from "recoil";
import { Box } from "@mui/joy";
import React, { useEffect, useRef } from "react";
import { layoutAtom } from "recoil/layoutAtom";
import { useMediaQuery, useTheme } from "@mui/material";

export default function ContentTemplateV2({
  children,
  noPadding,
  isWide,
  setContainerHeight,
}: {
  children: React.ReactNode;
  noPadding?: boolean;
  isWide?: boolean;
  setContainerHeight?: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { taskWidth } = useRecoilValue(layoutAtom);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (setContainerHeight && containerRef.current?.offsetHeight) {
        setContainerHeight(containerRef.current?.offsetHeight);
      }
    }, 100);
  }, [containerRef.current]);

  return (
    <div
      ref={containerRef}
      className={`h-full w-full overflow-y-auto ${
        !noPadding && "py-[20px] px-[20px]"
      }  overflow-x-hidden`}
      style={{
        overscrollBehavior: "none",
        WebkitOverflowScrolling: "auto", // 기본값은 'touch'로 iOS에서 바운스 발생
      }}
    >
      <Box
        className={`relative w-full  ${
          taskWidth === "normal" && `${!isWide && "max-w-[720px] mx-auto"}`
        } pb-[36px]`}
      >
        {children}
      </Box>
    </div>
  );
}