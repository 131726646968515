import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/joy'
import CustomTypography from './CustomTypography'
import { theme } from '../../styles/theme'

/*
  MultiTextTransition
  ────────────────────
  • textList: 표시할 문자열 배열 (예: ["문구1","문구2","문구3","문구4"])
  • fadeInTime: 페이드인 시간(ms)
  • displayTime: 화면에 유지되는 시간(ms)
  • fadeOutTime: 페이드아웃 시간(ms)
  • onComplete: 모든 문구 전환이 끝났을 때 호출될 콜백
*/
interface MultiTextTransitionProps {
  textList: string[];
  fadeInTime?: number;
  displayTime?: number;
  fadeOutTime?: number;
  onComplete?: () => void;
}

export default function MultiTextTransition({
  textList,
  fadeInTime = 500, // 디폴트 페이드인 0.5초
  displayTime = 2000, // 디폴트 유지 2초
  fadeOutTime = 500, // 디폴트 페이드아웃 0.5초
  onComplete,
}: MultiTextTransitionProps) {
  const [currentText, setCurrentText] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false); // opacity 제어
  const currentIndexRef = useRef<number>(0); // 현재 표시중인 문구 인덱스
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]); // 등록한 타이머 관리

  useEffect(() => {
    if (!textList || textList.length === 0) return;
    // 첫 문구부터 시작
    showText(0);

    // 언마운트 시 모든 타이머 정리
    return () => {
      timeoutsRef.current.forEach((t) => clearTimeout(t));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textList]);

  // index번째 문구를 fade-in → 유지 → fade-out 후 다음 문구로 넘어가는 함수
  function showText(index: number) {
    if (index >= textList.length) {
      // 모든 문구 소진 → onComplete()로 끝내기
      onComplete && onComplete();
      return;
    }
    currentIndexRef.current = index;

    // ⓵ 다음 문구로 교체 & fadeIn
    setCurrentText(textList[index]);
    setVisible(true);

    // ⓶ fadeIn 시간이 지난 뒤 → displayTime 동안 유지
    const fadeInTimer = setTimeout(() => {
      // ⓷ 유지 시간(= displayTime) 끝나면 fadeOut
      const displayTimer = setTimeout(() => {
        setVisible(false);
        // ⓸ fadeOut 끝난 뒤 showText(index+1)로 다음 문구
        const fadeOutTimer = setTimeout(() => {
          showText(index + 1);
        }, fadeOutTime);

        timeoutsRef.current.push(fadeOutTimer);
      }, displayTime);

      timeoutsRef.current.push(displayTimer);
    }, fadeInTime);

    timeoutsRef.current.push(fadeInTimer);
  }

  return (
    <Box
      sx={{
        // 부모 박스에 상대 배치
        position: "relative",
        width: "100%",
        height: "100%", // 원하는 크기로 지정
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // 페이드효과
        transition: `opacity ${fadeInTime}ms, visibility ${fadeOutTime}ms`,
        opacity: visible ? 1 : 0,
      }}
    >
      {/* 현재 표시중인 텍스트 */}
      <CustomTypography
        variant={"H3"}
        fontWeight={500}
        sx={{
          color: theme.vars.palette.Content.Highlight,
          textAlign: "center",
          whiteSpace: "pre-line",
          // 필요하면 스타일 추가
        }}
      >
        {currentText}
      </CustomTypography>
    </Box>
  );
}
