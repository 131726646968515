import React, { useState } from 'react'
import { Box, IconButton, styled, Typography } from '@mui/joy'
import { motion } from 'framer-motion'
import { theme } from '../../styles/theme'
import { QnaAnswerOption } from '../../pages/onboarding/part/convention/Convention_2_QnA'

// StyledIconButton에 대한 props 타입 정의
interface StyledIconButtonProps
  extends React.ComponentProps<typeof IconButton> {
  selected?: boolean;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "selected",
})<StyledIconButtonProps>(({ theme, selected }) => ({
  width: "47px",
  height: "47px",
  borderRadius: "47px",
  marginBottom: theme.spacing(1.25), // 10px
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${selected ? theme.vars.palette.Surface.Highlight : theme.vars.palette.Surface.Backward}`,
  "&:hover": "none",
  backgroundColor: theme.vars.palette.Surface.Forward,
  color: theme.vars.palette.Surface.Backward,
}));

// Circle 컴포넌트에 대한 props 타입 정의
interface CircleProps extends React.ComponentProps<typeof Box> {
  selected?: boolean;
}

const Circle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected",
})<CircleProps>(({ theme, selected }) => ({
  width: "25px",
  height: "25px",
  borderRadius: "25px",
  backgroundColor: theme.vars.palette.Surface.Backward,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& > div": {
    width: "100%",
    height: "100%",
    borderRadius: "25px",
    backgroundColor: theme.vars.palette.Content.Highlight,
    transform: selected ? "scale(1)" : "scale(0)",
    transition: "transform 0.2s",
  },
}));

export function SelectButtonCircleV2({
  selected,
  questionId,
  option,
  disable,
  handleClick,
}: {
  selected: boolean;
  questionId: number;
  option: QnaAnswerOption;
  disable: boolean;
  handleClick: (questionId: number, answerId: number) => void;
}) {
  const [buttonDown, setButtonDown] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "47px",
        position: "relative",
      }}
      onMouseDown={() => {
        setButtonDown(true);
      }}
      onClick={() => {
        if (!disable) {
          handleClick(questionId, option.id);
        }
      }}
      onTouchStart={() => {
        setButtonDown(true);
      }}
      onTouchEnd={(e) => {
        setButtonDown(false);
        if (
          document
            .elementsFromPoint(
              e.changedTouches[0].clientX,
              e.changedTouches[0].clientY
            )
            .includes(e.currentTarget) &&
          !disable
        ) {
          handleClick(questionId, option.id);
        }
      }}
    >
      <motion.div
        animate={{ scale: buttonDown && !disable ? 0.95 : 1 }}
        transition={{ duration: 0.2 }}
      >
        <StyledIconButton disabled={disable} selected={selected}>
          <Circle selected={selected}>
            <div />
          </Circle>
        </StyledIconButton>
      </motion.div>
      <Typography
        variant="Caption"
        sx={{
          userSelect: "none",
          fontWeight: selected ? 700 : 500,
          color: selected
            ? theme.vars.palette.Content.Highlight
            : theme.vars.palette.Content.Strong,
          whiteSpace: "nowrap",
        }}
      >
        {option.text}
      </Typography>
    </Box>
  );
}
