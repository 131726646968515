import { Stack, Typography } from "@mui/joy";
import ButtonWithModal from "components/common/ButtonWithModal";
import Link from "components/common/Link";
import useLinkData from "hooks/useLinkData";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { programDataAtom } from "recoil/programDataAtom";
import { taskListAtom } from "recoil/taskListAtom";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { userAtom, UserType } from "recoil/userAtom";
import { sendMessage } from "utils/webview";
import mixpanel from "mixpanel-browser";
import useIsInappWebview from "hooks/useIsInappWebview";
import { onboardingDataState } from "../../recoil/onboardingDataAtom";
import LinkV2 from '../common/LinkV2'
import { theme } from '../../styles/theme'

export default function UserInfo({ user }: { user: UserType }) {
  const isInappWebview = useIsInappWebview();
  const { subscriptionCancelLink } = useLinkData();
  const { isSubscribingWithoutCancel } = useSubscriptionStatus();
  const goCancelSubscription = () => {
    if (isInappWebview) {
      sendMessage(
        JSON.stringify({
          event: "open_link",
          params: {
            url: subscriptionCancelLink,
          },
        })
      );
    } else {
      window.open(subscriptionCancelLink);
    }
  };

  const navigate = useNavigate();
  const resetUser = useResetRecoilState(userAtom);
  const resetPid = useResetRecoilState(patientAtom);
  const resetProgramData = useResetRecoilState(programDataAtom);
  const resetTaskSection = useResetRecoilState(taskSectionAtom);
  const resetTaskState = useResetRecoilState(taskListAtom);
  const resetOnboardingData = useResetRecoilState(onboardingDataState);
  const initializeUser = () => {
    resetUser();
    resetPid();
    resetProgramData();
    resetTaskSection();
    resetTaskState();
    resetOnboardingData();
    // navigate("/login", { replace: true });
    window.location.href = `${window.location.origin}/login`;
    sendMessage(
      JSON.stringify({
        event: "logout",
      })
    );
    mixpanel.reset();
  };

  return (
    <ButtonWithModal
      buttonText={""}
      size="lg"
      title={"내 정보"}
      textAlign="center"
      render={<LinkV2 text={"내 정보"} />}
      content={
        <Stack
          alignItems={"center"}
          spacing={"24px"}
          sx={{ width: "100%", color: "text.tertiary" }}
        >
          <Stack spacing={"12px"}>
            {[
              { title: "닉네임", content: user.nickname },
              ...(user.role === "patient"
                ? [{ title: "회원 번호", content: user.userId }]
                : []),
              ...(user.benefit
                ? [{ title: "혜택", content: user.benefit }]
                : []),
            ].map(({ title, content }) => (
              <Stack
                direction={"row"}
                justifyContent="space-between"
                gap="8px"
                alignItems="center"
                sx={{ width: "224px", whiteSpace: "nowrap" }}
              >
                <Typography
                  variant={"Body"}
                  sx={{
                    color: theme.vars.palette.Content.Medium,
                    fontWeight: 500,
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  variant={"Body"}
                  sx={{
                    color: theme.vars.palette.Content.Strong,
                    fontWeight: 700,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {content}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems="center"
            spacing="20px"
          >
            <ButtonWithModal
              render={
                <LinkV2
                  text={"로그아웃"}
                />
              }
              buttonText={"로그아웃"}
              fetchActionUrl={"/user/logout"}
              title={"정말로 로그아웃할까요?"}
              actionText={"로그아웃 하기"}
              actionButtonColor={"danger"}
              actionAfterFetch={initializeUser}
            />
            <ButtonWithModal
              render={
                <LinkV2
                  text={"회원탈퇴"}
                />
              }
              buttonText={"회원탈퇴"}
              title={
                isSubscribingWithoutCancel
                  ? "탈퇴하기 전에 이용 중인 구독을 먼저 취소해 주세요"
                  : "정말로 탈퇴할까요?"
              }
              actionText={
                isSubscribingWithoutCancel ? "구독 취소하기" : "탈퇴하기"
              }
              actionButtonColor={"danger"}
              {...(isSubscribingWithoutCancel
                ? { action: goCancelSubscription }
                : {
                    fetchActionUrl: "/user/leave",
                    actionAfterFetch: initializeUser,
                  })}
            />
          </Stack>
        </Stack>
      }
    />
  );
}