import { Box, Stack } from "@mui/joy";
import React, { useEffect } from "react";
import logo from "static/image/common/distancing_logo.png";
import FullPageContainer from "./FullPageContainer";
import useIsInappWebview from "hooks/useIsInappWebview";
import { sendMessage } from "utils/webview";
import { theme } from "styles/theme";
import { extractHexColor } from "../../styles/hexOpacity";

export default function DefaultPage({
  content,
  backgroundColor = theme.vars.palette.Background.Level1,
}: {
  content: React.ReactNode;
  backgroundColor?: string;
}) {
  const isInappWebview = useIsInappWebview();

  useEffect(() => {
    if (isInappWebview) {
      const hexValue = extractHexColor(backgroundColor);

      if (hexValue) {
        sendMessage(
          JSON.stringify({
            event: "set_safearea_color",
            params: {
              color: `${hexValue}`,
            },
          })
        );
      }
    }
  }, [backgroundColor]);

  return (
    <FullPageContainer hasFooter backgroundColor={backgroundColor}>
      <Box>
        <img src={logo} alt={"empty"} style={{ width: "132px" }} />
      </Box>
      <Stack
        flexGrow={1}
        justifyContent="center"
        sx={{ width: "100%", px: "20px" }}
      >
        {content}
      </Stack>
    </FullPageContainer>
  );
}