import React from "react";
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import text_decorator from "static/image/onboarding/text_decorator.svg";
import ButtonProgress from "components/common/BottonProgress";

export default function Goal_10() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/goal/11`);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "70vh" }}>
      <Stack
        spacing={1.5}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={700}
          sx={{
            color: theme.vars.palette.Content.Highlight,
          }}
        >
          {"오늘 배워 내일 실천합니다."}
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {"디스턴싱은 내 삶에 당장 활용할 수 있는 실용적인 기법을 가르칩니다."}
        </CustomTypography>

        <Box
          sx={{
            flex: 1,
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 4, // 요소 간 32px 간격
          }}
        >
          <img
            src={text_decorator}
            alt={"onboarding"}
            style={{
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
          <CustomTypography
            variant="H3"
            fontWeight={500}
            sx={{
              textAlign: "center",
              color: theme.vars.palette.Content.Strong,
            }}
          >
            {"디스턴싱을 만나고\n" +
              "내 삶의 주인이 되었습니다.\n" +
              "이제 부정적인 감정에\n" +
              "일상이 흔들리지 않습니다."}
          </CustomTypography>

          <CustomTypography
            variant="Body"
            fontWeight={500}
            sx={{
              color: theme.vars.palette.Content.Medium,
            }}
          >
            {"디스턴싱 유저 최**"}
          </CustomTypography>
        </Box>
      </Stack>
      <ButtonProgress handleNext={handleNext} />
    </Box>
  );
}
