import { styled, Typography, TypographyProps } from "@mui/joy";
import { Box, Checkbox, CheckboxProps } from "@mui/material";
import { theme } from "../../styles/theme";
import { CheckIconSvg } from "../../static/image/common/SvgAssets";
import React from "react";

interface SelectCheckboxProps {
  label: string;
  checked: boolean;
  onChange: () => void;
}

// ➊ 영역 스타일: checked 값에 따라 조건부 스타일 적용
//    shouldForwardProp: (prop) => prop !== "checked" 를 통해
//    실제 DOM에 "checked" 속성이 그대로 전달되지 않도록 제어
const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "checked",
})<{
  checked: boolean;
}>(({ theme, checked }) => ({
  display: "inline-flex",
  alignItems: "center",
  paddingLeft: "20px",
  height: "54px",
  margin: "4px 0",
  borderRadius: "12px",
  cursor: "pointer",
  boxSizing: "border-box", // 테두리를 레이아웃에 포함

  backgroundColor: checked
    ? theme.vars.palette.Surface.SoftHighlight
    : theme.vars.palette.Surface.Backward,

  border: "1.4px solid",
  borderColor: checked ? theme.vars.palette.Border.Highlight : "transparent",

  transition: "all 0.2s ease-in-out",
}));

// ➋ 체크박스 아이콘 커스텀
//    함수 선언 방식으로 변경
function StyledCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      disableRipple
      sx={{ padding: 0 }}
      checkedIcon={
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor: theme.vars.palette.Surface.Highlight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
          }}
        >
          <CheckIconSvg />
        </Box>
      }
      icon={
        <Box
          sx={{
            width: 20,
            height: 20,
            borderRadius: "4px",
            border: `1.2px solid ${theme.vars.palette.Border.Medium}`,
          }}
        />
      }
      {...props}
    />
  );
}

// ➌ 체크박스 옆 라벨 스타일
//    라벨도 checked 값에 따라 다른 색상을 적용하기 위해
//    shouldForwardProp: (prop) => prop !== "checked" 설정 후,
//    styled에 <{checked:boolean}> 타입을 추가
const StyledLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "checked",
})<TypographyProps & { checked: boolean }>(({ theme, checked }) => ({
  color: checked
    ? theme.vars.palette.Content.Highlight
    : theme.vars.palette.Content.Strong,
  fontWeight: checked ? 700 : 500,
  paddingLeft: "12px",
}));

// ➍ 실제로 사용할 커스텀 체크박스 컴포넌트
//    함수 선언 방식으로 변경
export default function SelectCheckbox({
  label,
  checked,
  onChange,
}: SelectCheckboxProps) {
  return (
    <StyledContainer
      checked={checked}
      onClick={onChange} // 컨테이너 클릭으로도 체크/해제
    >
      <StyledCheckbox checked={checked} onChange={() => onChange} />
      <StyledLabel checked={checked} variant="Body">
        {label}
      </StyledLabel>
    </StyledContainer>
  );
}