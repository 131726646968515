import React, { useEffect, useRef } from "react";
import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import CustomTypography from "components/common/CustomTypography";
import { useNavigate } from "react-router-dom";
import { BottomNextButton } from "components/common/BottomNextButton";
import ResultChart from "components/common/ResultChart";
import { useOnboardingData } from "../../../../hooks/useOnboardingData";
import { selectOptionList } from "../goal/Goal_6";
import { useMutation } from "@tanstack/react-query";
import { onboardingApi } from "../../../../api/onboardingApi";

export default function Result_4() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/onboarding_new/payment/1`, { replace: true });
  };

  const { singleSelectionId } = useOnboardingData("goal_6");
  const option = selectOptionList.find((e) => e.id === singleSelectionId);
  const labelPercentage = (option?.score || 24) + 16;

  const pageKey = "result_4";

  const { mutate: savePage } = useMutation(
    (pageId: string) => onboardingApi.savePage(pageId),
    {
      onSuccess: () => {},
    }
  );

  function useEffectOnce(callback: () => void) {
    const ref = useRef(false);

    useEffect(() => {
      if (ref.current) return;
      ref.current = true;

      if (typeof callback === "function") {
        callback();
      }
    }, []);
  }

  useEffectOnce(() => {
    savePage(pageKey);
  });

  return (
    <Stack sx={{ paddingBottom: "120px" }}>
      <Stack
        spacing={1.5}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          variant="Subtitle"
          fontWeight={700}
          sx={{
            color: theme.vars.palette.Content.Highlight,
          }}
        >
          {"최종 예측입니다."}
        </CustomTypography>
        <CustomTypography
          variant="Subtitle"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {`8주 뒤 증상이 ${labelPercentage}% 호전될 것으로 예상됩니다.`}
        </CustomTypography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // 수평 가운데 정렬
          }}
        >
          <Box padding="40px 24px" maxWidth="380px" width="100%">
            <ResultChart labelValue={`${labelPercentage}%`} />
          </Box>
        </Box>
      </Stack>
      <Stack spacing={3}>
        <CustomTypography
          variant="Body"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Medium,
          }}
        >
          {`대단히 훌륭합니다. 나의 모든 답변을 종합해 분석한 결과, 기존 예상치보다 `}
          <Box
            component="span"
            sx={{
              color: theme.vars.palette.Content.Highlight,
              fontWeight: 700,
            }}
          >{`16%p 더 큰 폭`}</Box>
          {`의 증상 개선을 이룰 수 있을 것으로 예측됩니다.`}
        </CustomTypography>
        <CustomTypography
          variant="Body"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Medium,
          }}
        >
          {"이후 약 한 달 동안은 추가적인 진전과 함께 디스턴싱 습관이 "}
          <Box
            component="span"
            sx={{
              color: theme.vars.palette.Content.Highlight,
              fontWeight: 700,
            }}
          >{`삶 속에 안정적으로 자리잡을 수 있도록`}</Box>
          {" 훈련하는 데에 집중할 것입니다."}
        </CustomTypography>
        <CustomTypography
          variant="Caption"
          fontWeight={500}
          sx={{
            color: theme.vars.palette.Content.Medium,
          }}
        >
          {"* 위 예측치는 15만 개의 데이터 포인트로 분석한 결과임"}
        </CustomTypography>
      </Stack>
      <BottomNextButton disabled={false} onClick={handleNext} text={"다음"} />
    </Stack>
  );
}