import React, {
  KeyboardEventHandler,
  RefObject,
  useEffect,
  useState,
} from "react";
import { Textarea } from "@mui/joy";
import { isMobile } from "react-device-detect";
import emojiRegex from "emoji-regex";
import { theme } from "styles/theme";

interface TextareaOnboardingProps {
  placeholder?: string;
  initialValue?: string;
  handleChange: (value: string) => void;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  emojiDisabled?: boolean;
  readOnly?: boolean;
  /* 자식 내부에 실제 <textarea>에 ref를 연결할 수 있도록 받음 */
  inputRef?: RefObject<HTMLTextAreaElement>;
  textAlign: 'start' | 'center'
}

export default function TextareaOnboarding({
  placeholder,
  initialValue,
  handleChange,
  isFocused,
  setIsFocused,
  emojiDisabled,
  readOnly,
  inputRef,
  textAlign,
}: TextareaOnboardingProps) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (!isFocused) {
      setValue(initialValue);
    }
  }, [initialValue, isFocused]);

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (
      !isMobile &&
      (e.code === "Enter" ||
        e.code === "NumpadEnter" ||
        e.key === "Enter" ||
        e.key === "NumpadEnter")
    ) {
      // 필요 시 엔터 입력 로직
    }
  };

  const emoji_regex = emojiRegex();
  const disabled_regex = /[^a-zA-Z0-9ㄱ-힣]/g;

  return (
    <Textarea
      placeholder={placeholder}
      value={value || ""}
      maxRows={1}
      onChange={(e) => {
        let newValue = e.target.value;
        if (emojiDisabled) {
          newValue = newValue.replace(disabled_regex, "");
        }
        setValue(newValue);
        handleChange(newValue);
      }}
      readOnly={readOnly || false}
      minRows={1}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onKeyDown={handleKeyDown}
      /* slotProps로 내부 textarea에 ref 연결 */
      slotProps={{
        textarea: {
          ref: inputRef, // 부모에서 내려받은 textAreaRef 연결
        },
      }}
      sx={{
        width: "100%",
        boxShadow: "none",
        backgroundColor: "transparent",
        padding: "0 20px",
        border: "none",
        minWidth: 0,
        minHeight: "24px",
        "--joy-focus-thickness": "0px",
        "--variant-borderWidth": "0px",
        "--Textarea-gap": "0px",
        "& textarea": {
          textAlign: `${textAlign}`,
          padding: 0,
          margin: 0,
          fontWeight: 500,
          color: theme.vars.palette.Content.Strong,
          whiteSpace: "nowrap", // 줄바꿈 없이 한 줄로 표시
          overflowX: "auto", // 내용이 넘치면 가로 스크롤
          resize: "none", // 드래그로 크기 변경 불가
        },
        "& textarea::placeholder": {
          textAlign: `${textAlign}`,
        },
      }}
    />
  );
}