import { Box, Stack } from "@mui/joy";
import { theme } from "styles/theme";
import React, { useEffect, useRef, useState } from "react";
import useDetectIOS from "hooks/useDetectIOS";
import TextareaOnboarding from "components/onboarding/TextareaOnboarding";
import KeyboardAwareBottomButton from "components/onboarding/KeyboardAwareBottomButton";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { acquisitionApi } from "../../../../api/acquisitionApi";
import CustomTypography from "../../../../components/common/CustomTypography";

export function AcquisitionEtcDetail() {
  const { isIOS } = useDetectIOS();
  const [value, setValue] = useState<string>("");
  const textareaRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus(); // 컴포넌트 마운트 후 포커스 설정
    }
  }, []); // 빈 배열을 전

  const { mutate: submit } = useMutation(
    () => acquisitionApi.submit("기타", value),
    {
      onSuccess: () => {
        setLoading(false);
        navigate("/onboarding_new/acquisition/3");
      },
    }
  );

  function handleSubmit() {
    setLoading(true);
    submit();
  }

  return (
    <Stack spacing={3} sx={{ height: "100%" }}>
      <CustomTypography
        variant="Subtitle"
        fontWeight={500}
        sx={{
          color: theme.vars.palette.Content.Strong,
        }}
      >
        어디서 보고 오셨나요?
      </CustomTypography>

      <Box
        sx={{
          width: "100%",
          height: "54px",
          backgroundColor: theme.vars.palette.Surface.Forward,
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          boxShadow: isFocused
            ? `inset 0 0 0 1.4px ${theme.vars.palette.Content.Highlight}`
            : `inset 0 0 0 0`,
        }}
      >
        <TextareaOnboarding
          initialValue={value}
          handleChange={(newValue: string) => setValue(newValue)}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          emojiDisabled={true}
          readOnly={false}
          textAlign={"start"}
          /* ➌ 여기서 자식(TextareaOnboarding)에 ref를 넘김 */
          // inputRef={textAreaRef}
        />
      </Box>

      <KeyboardAwareBottomButton
        loading={loading}
        disabled={value === undefined || value === ""}
        onClick={handleSubmit}
        text={"다음"}
        highlightBackground={false}
      />
    </Stack>
  );
}