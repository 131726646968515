import { useRecoilState } from "recoil";
import { onboardingDataState } from "recoil/onboardingDataAtom";

export function useOnboardingData(pageKey: string) {
  const [onboardingData, setOnboardingData] =
    useRecoilState(onboardingDataState);

  // ➊ selectionIdList 읽기 (기본값: 빈 배열)
  const multiSelectionIdList =
    onboardingData[pageKey]?.multiSelectionIdList ?? [];

  // ➋ 다중 선택(체크박스 등) 업데이트 함수
  const updateMultiSelection = (optionId: number) => {
    setOnboardingData((prev) => {
      const oldPageState = prev[pageKey] ?? {};
      const oldList = oldPageState.multiSelectionIdList ?? [];

      let newSelectedOptions: number[];
      if (oldList.includes(optionId)) {
        // 이미 있으면 제거
        newSelectedOptions = oldList.filter((id) => id !== optionId);
      } else {
        // 없으면 추가
        newSelectedOptions = [...oldList, optionId];
      }

      // 불변성: 기존 prev 객체는 변경하지 않고,
      // 새 객체를 만들어(…) 리턴해야 Recoil이 값이 바뀐 것을 인식
      return {
        ...prev,
        [pageKey]: {
          ...oldPageState,
          multiSelectionIdList: newSelectedOptions,
        },
      };
    });
  };

  // ➊ selectionIdList 읽기 (기본값: 빈 배열)
  const singleSelectionId =
    onboardingData[pageKey]?.singleSelectionId ?? undefined;

  // ➋ 단일 선택 업데이트 함수
  const updateSingleSelection = (optionId: number) => {
    setOnboardingData((prev) => {
      const oldPageState = prev[pageKey] ?? {};
      return {
        ...prev,
        [pageKey]: {
          ...oldPageState,
          singleSelectionId: optionId,
        },
      };
    });
  };

  // ➌ qaList 읽기 (기본값: 빈 배열)
  const qaList = onboardingData[pageKey]?.qaList ?? [];

  // ➍ qaList 업데이트(개별 questionId에 대한 answerId 수정/추가) 함수
  const updateQaList = (questionId: number, answerId: number) => {
    setOnboardingData((prev) => {
      const oldPageState = prev[pageKey] ?? {};
      const oldQaList = oldPageState.qaList ?? [];

      // 이미 동일 questionId가 있으면 업데이트, 없으면 새로 추가
      const existingIndex = oldQaList.findIndex(
        (item) => item.questionId === questionId
      );

      let newQaList;
      if (existingIndex !== -1) {
        // 기존 항목 교체
        newQaList = [...oldQaList];
        newQaList[existingIndex] = { questionId, answerId };
      } else {
        // 항목 새로 추가
        newQaList = [...oldQaList, { questionId, answerId }];
      }

      return {
        ...prev,
        [pageKey]: {
          ...oldPageState,
          qaList: newQaList,
        },
      };
    });
  };

  const text = onboardingData[pageKey]?.text ?? "";

  // text를 새 값으로 설정하는 함수
  const updateText = (newText: string) => {
    setOnboardingData((prev) => {
      const oldPageState = prev[pageKey] ?? {};
      return {
        ...prev,
        [pageKey]: {
          ...oldPageState,
          text: newText,
        },
      };
    });
  };

  return {
    multiSelectionIdList: multiSelectionIdList,
    updateMultiSelection: updateMultiSelection,
    singleSelectionId: singleSelectionId,
    updateSingleSelection: updateSingleSelection,
    qaList,
    updateQaList,
    text,
    updateText,
  };
}