import { atom } from "recoil";

interface OnboardingDataState {
  [pageKey: string]: DataState; // pageKey key로 하여 SelectState를 값으로 담는 객체
}

export interface QA {
  questionId: number;
  answerId: number;
}

interface DataState {
  multiSelectionIdList?: number[];
  singleSelectionId?: number;
  qaList?: QA[];
  text?: string;
}

/*
  localStorage key 상수
  */
const LOCAL_STORAGE_KEY = "onboardingData";

/*
  Atom Effects:
  - setSelf: atom 초깃값을 세팅
  - onSet: atom 값이 바뀔 때 호출
*/
/*
  Atom Effects:
  - setSelf: atom 초깃값을 세팅
  - onSet: atom 값이 바뀔 때 호출
*/
const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    // ① 브라우저에 저장된 값(localStorage) 있으면 불러와서 atom 초기값 세팅
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
      try {
        const parsed = JSON.parse(savedValue);
        if (parsed) {
          setSelf(parsed);
        }
      } catch (error) {
        console.warn("Error parsing localStorage data:", error);
      }
    }

    // ② atom 값 바뀔 때마다 localStorage에 저장
    onSet(
      (newValue: OnboardingDataState, _: OnboardingDataState, __: boolean) => {
        console.log(JSON.stringify(newValue))
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    );
  };

export const onboardingDataState = atom<OnboardingDataState>({
  key: "onboardingDataState",
  default: {},
  effects: [
    localStorageEffect(LOCAL_STORAGE_KEY),
    // ↑ onSet / setSelf 로컬스토리지 연동
  ],
});