import {
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithTextarea,
} from "../../BlockComponent";
import {
  Audio,
  ButtonGroup,
  DateSelect,
  Image,
  LineWithSolidLabel,
  Percentage,
  TextareaLine,
} from "../../CellComponent";
import dayjs from "dayjs";

const today = dayjs().format("YYYY-MM-DD");

export type MeditationType =
  | "grounding"
  | "relaxation"
  | "breath"
  | "willingness"
  | "routine";

export const MeditationTemplate_new = (
  includingMeditation: MeditationType[],
  mrk?: string,
  isFromThoughtRecord?: boolean,
  isSituationUpgraded?: boolean
) => {
  const isWillingnessAvailable = includingMeditation.includes("willingness");

  return [
    InstructionWithContent(
      "typography_0",
      [
        [
          ButtonGroup(
            [
              ...(includingMeditation.includes("grounding")
                ? [
                    {
                      text: "지금 느끼는 괴로움을 다스리러 왔어요",
                      showFromId: "coping",
                      hideFromId: "routine",
                    },
                  ]
                : []),
              ...(includingMeditation.includes("routine")
                ? [
                    {
                      text: "오늘 계획한 훈련을 하러 왔어요",
                      showFromId: "routine",
                      hideFromId: "coping",
                    },
                  ]
                : []),
            ],
            { id: "coping_or_routine" }
          ),
        ],
        [
          {
            type: "temp",
            content: {
              id: `date`,
              value: today,
            },
          },
        ],
      ],
      { blockId: "coping_or_routine" },
      true
    ),

    InstructionWithButtonGroup(
      "coping_0",
      [
        {
          text: "감정 - 심호흡 그라운딩",
          showFromId: "grounding",
          hideFromId: "relaxation",
        },
        {
          text: "신체 반응 - 근육이완법",
          showFromId: "relaxation",
          hideFromId: "breath",
        },
        ...(includingMeditation.includes("breath")
          ? [
              {
                text: "생각 - 호흡 명상",
                showFromId: "breath",
                hideFromId: "willingness",
              },
            ]
          : []),
        ...(includingMeditation.includes("willingness")
          ? [
              {
                text: "심하진 않음 - 기꺼이 경험하기",
                showFromId: "willingness",
                hideFromId: "grounding",
              },
            ]
          : []),
      ],
      { id: "coping" },
      { blockId: "coping" }
    ),
    ...[
      { blockId: "grounding", imageSrc: "1", audioUrl: `grounding` },
      { blockId: "relaxation", imageSrc: "2", audioUrl: `relaxation` },
      { blockId: "breath", imageSrc: "3", audioUrl: `breath` },
      { blockId: "willingness", imageSrc: "3", audioUrl: `willingness` },
    ]
      .map(({ blockId, imageSrc, audioUrl }, index) => [
        Instruction(`coping_${blockId}_0`, { blockId: blockId }),
        InstructionWithContent("audio_instruction", [
          Image(imageSrc),
          Audio(audioUrl),
        ]),
        Instruction(`coping_${blockId}_1`, {
          ...((!isWillingnessAvailable ||
            (isWillingnessAvailable && blockId === "willingness")) && {
            nextBlockId: isFromThoughtRecord ? "wrapup" : "ask_deeper",
          }),
        }),
        ...(isWillingnessAvailable && blockId !== "willingness"
          ? [
              InstructionWithButtonGroup(`coping_${blockId}_2`, [
                {
                  text: "기꺼이 경험하기 명상 훈련을 이어서 해볼게요",
                  showFromId: "willingness",
                  hideFromId: isFromThoughtRecord ? "wrapup" : "ask_deeper",
                },
                {
                  text: "괜찮아요",
                  showFromId: isFromThoughtRecord ? "wrapup" : "ask_deeper",
                  hideFromId: "willingness",
                },
              ]),
            ]
          : []),
      ])
      .flat(),

    ...(!isFromThoughtRecord
      ? [
          InstructionWithButtonGroup(
            `coping_1`,
            [
              {
                text: "네",
                showFromId: "thoughtRecord_start",
                hideFromId: "wrapup",
              },
              {
                text: "아니요",
                showFromId: "wrapup",
                hideFromId: "thoughtRecord_start",
              },
            ],
            undefined,
            { blockId: "ask_deeper" }
          ),

          InstructionWithContent(
            `thoughtRecord_impact`,
            [
              ...LineWithSolidLabel("영향 정도", [
                Percentage({
                  id: `percentageThought`,
                }),
              ]),
            ],
            { blockId: "thoughtRecord_start" }
          ),
          InstructionWithContent(
            `thoughtRecord_date`,
            LineWithSolidLabel("날짜", [
              DateSelect({ id: "date", value: today }),
            ])
          ),

          InstructionWithContent(
            `thoughtRecord_situation`,
            isSituationUpgraded
              ? [
                  ...LineWithSolidLabel(
                    "간접 상황",
                    TextareaLine({
                      id: "situation_indirect",
                      placeholder: "최근의 맥락",
                    })
                  ),
                  ...LineWithSolidLabel(
                    "직접 상황",
                    TextareaLine({
                      id: "situation_direct",
                      placeholder: "직전의 트리거",
                    })
                  ),
                ]
              : [
                  ...LineWithSolidLabel(
                    "상황",
                    TextareaLine({ id: "situation" })
                  ),
                ]
          ),
          Instruction("coping_3", { nextBlockId: "wrapup" }),
        ]
      : []),

    InstructionWithButtonGroup(
      `routine_0`,
      [
        {
          text: "5분",
          showFromId: `5min`,
          hideFromId: `10min`,
        },
        {
          text: "10분",
          showFromId: `10min`,
          hideFromId: `5min`,
        },
        {
          text: "15분",
          showFromId: `15min`,
          hideFromId: `5min`,
        },
        {
          text: "20분",
          showFromId: `20min`,
          hideFromId: `5min`,
        },
      ],
      undefined,
      { blockId: "routine" }
    ),
    ...["5min", "10min", "15min", "20min"]
      .map((duration) => [
        InstructionWithButtonGroup(
          `routine_1`,
          [
            {
              text: "생각의 강",
              showFromId: `stream_${duration}`,
              hideFromId: `sky_${duration}`,
            },
            {
              text: "생각의 하늘",
              showFromId: `sky_${duration}`,
              hideFromId: `stream_${duration}`,
            },
            {
              text: "생각의 공장",
              showFromId: `factory_${duration}`,
              hideFromId: `stream_${duration}`,
            },
          ],
          undefined,
          { blockId: duration }
        ),
        ...[
          {
            blockId: `stream_${duration}`,
            imageSrc: "4",
            audioUrl: `stream_of_thoughts_continue_${duration}`,
            extraAudioUrl: `stream_of_thoughts_loop_5min`,
          },
          {
            blockId: `sky_${duration}`,
            imageSrc: "5",
            audioUrl: `sky_of_thoughts_continue_${duration}`,
            extraAudioUrl: `sky_of_thoughts_loop_5min`,
          },
          {
            blockId: `factory_${duration}`,
            imageSrc: "6",
            audioUrl: `factory_of_thoughts_continue_${duration}`,
            extraAudioUrl: `factory_of_thoughts_loop_5min`,
          },
        ].map(({ blockId, imageSrc, audioUrl, extraAudioUrl }) =>
          InstructionWithContent(
            "audio_instruction",
            [Image(imageSrc), Audio(`${audioUrl}`, extraAudioUrl, mrk)],
            { blockId: blockId, nextBlockId: "routine_wrapup" }
          )
        ),
      ])
      .flat(),

    InstructionWithData({ A: { id: "meditation_time" } }, "routine_2", {
      blockId: "routine_wrapup",
    }),
    Instruction("routine_3"),
    Instruction("routine_4"),
    InstructionWithTextarea("routine_5", { optional: true }),
    Instruction("wrapup_0", { blockId: "wrapup" }),
    Instruction("wrapup_1"),
  ];
};
