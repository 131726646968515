import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/joy'
import { theme } from '../../styles/theme'

/* 
  midSlowEasing(t): 0~1 사이 t값을 입력받아
  "초반 빠름 → 중반 느림 → 후반 빠름" 형태의 결과값(0~1)을 반환하는 커스텀 이징.
  - 구간 분할:
    1) t in [0, 0.2)  : 빠르게 증가 (easeInQuad)
    2) t in [0.2, 0.8): 중반 천천히 (linear로 0.35→0.65)
    3) t in [0.8, 1]   : 마지막 빠름 (easeOutQuad)
*/
function midSlowEasing(t: number): number {
  if (t < 0) t = 0;
  if (t > 1) t = 1;

  if (t < 0.2) {
    // 0.2 구간: 0% -> 20%
    // easeInQuad: f(x) = x^2
    const localT = t / 0.2; // 0→1
    const eased = localT * localT; // 0→1
    // 전체 y범위: 0→0.35 (초반 꽤 빠르게)
    return eased * 0.35;
  } else if (t < 0.8) {
    // 0.8 구간: 0.2→0.8
    // 중반부는 linear로 자연스럽게 0.35→0.65
    const localT = (t - 0.2) / 0.6; // 0→1
    // 0.35 + localT*(0.65 - 0.35) = 0.35 + 0.3*localT
    return 0.35 + 0.3 * localT;
  } else {
    // 마지막 0.2 구간: 0.8→1
    // easeOutQuad: f(x) = 1 - (1-x)^2
    const localT = (t - 0.8) / 0.2; // 0→1
    const eased = 1 - (1 - localT) * (1 - localT);
    // 전체 y범위: 0.65→1
    return 0.65 + 0.35 * eased;
  }
}

export default function BottomProgressBar({
  handleNext,
}: {
  handleNext: () => void;
}) {
  // 진행 상태(0% ~ 100%)
  const [progress, setProgress] = useState(0);

  // setInterval을 한 번에 정리하기 위해 ref로 저장
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const totalDuration = 8000; // 전체 8초간 진행
    const steps = 160; // 총 160단계 (약 50ms 간격)
    const intervalTime = totalDuration / steps; // ~50ms

    let currentStep = 0;

    intervalRef.current = setInterval(() => {
      currentStep++;
      const fraction = currentStep / steps; // 0→1
      // 커스텀 이징 함수 적용
      const eased = midSlowEasing(fraction);
      // 실제 프로그레스 0%→100%
      setProgress(Math.floor(eased * 100));

      if (currentStep >= steps) {
        // 끝까지 도달하면 100% 보장
        setProgress(100);
        // 1초 뒤 handleNext
        setTimeout(() => {
          handleNext();
        }, 1000);

        // 더는 반복하지 않도록 clearInterval
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }, intervalTime);

    // 컴포넌트 언마운트 시 타이머 정리
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [handleNext]);

  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        zIndex: 1200,
        background:
          `linear-gradient(180deg, rgba(246, 249, 250, 0) 0%, ${theme.vars.palette.Background.Level1} 20%)`,
        p: 2,
        display: 'flex', // flexbox 사용
        justifyContent: 'center', // 가로 가운데 정렬
      }}
    >
      <Box
        sx={{
          maxWidth: "680px",
          width: "100%",
          height: "54px",
          backgroundColor: "#E2EEFA",
          borderRadius: "12px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* 채워지는 바 */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            width: `${progress}%`,
            backgroundColor: "#2E5CAB",
            // 각 스텝 업데이트마다 약간의 transition으로 연결감
            transition: "width 0.1s ease-out",
          }}
        />
        {/* 문구 */}
        <Box
          sx={{
            position: "absolute",
            inset: 0, // top=0, right=0, bottom=0, left=0
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFFFFF",
            fontWeight: 700,
          }}
        >
          답변 분석 중...
        </Box>
      </Box>
    </Box>
  );
}
