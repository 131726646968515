import React from "react";
import { Box, Button } from "@mui/joy";
import { theme } from "../../styles/theme";

export function BottomNextButton({
  disabled,
  onClick,
  text,
  highlightBackground,
  loading = false,
}: {
  disabled: boolean;
  onClick: () => void;
  text: string;
  highlightBackground?: boolean;
  loading?: boolean;
}) {
  return (
    <Box
      sx={{
        // 화면 하단에 고정
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        zIndex: 1200,
        background: !highlightBackground // TODO 리팩토링
          ? `linear-gradient(180deg, rgba(246, 249, 250, 0) 0%, ${theme.vars.palette.Background.Level1} 20%)`
          : `background: linear-gradient(180deg, rgba(220, 239, 250, 0) 0%, ${theme.vars.palette.Background.Level1} 20%)`,
        // background: linear-gradient(180deg, rgba(220, 239, 250, 0) 0%, #DCEFFA 20%);

        // TODO home indicator 있는 경우 고려
        p: 2, // 16px
        display: 'flex', // flexbox 사용
        justifyContent: 'center', // 가로 가운데 정렬
      }}
    >
      <Button
        loading={loading}
        variant={"Action"}
        sx={{
          maxWidth: "680px",
          width: "100%",
          height: "54px",
          fontWeight: 700,
          borderRadius: "12px",

          // TODO Font: Body <- Typography 에만 적용되어 있음
          fontSize: "16px",
          lineHeight: "132%",
        }}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </Button>
    </Box>
  );
}