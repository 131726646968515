import { Button, Stack } from "@mui/joy";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOnboardingData } from "hooks/useOnboardingData";
import { theme } from "styles/theme";
import CustomTypography from "../../../../components/common/CustomTypography";

function XMarkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.25 7.5L7.25 17.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17.25 17.5L7.25 7.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 11.3462L10.7353 17.5L18.5 7.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

interface Question {
  questionId: number;
  text: string;
}

const questionList: Question[] = [
  {
    questionId: 1,
    text: "나는 지난 과거를 곱씹으며\n" + "답을 찾으려 애쓴다.",
  },
  {
    questionId: 2,
    text: "나는 다가올 미래를 예측하고\n" + "대비하려 노력한다.",
  },
  {
    questionId: 3,
    text: "나는 부정적인 생각이 들면\n" + "주의를 돌려 그것을 덮으려 한다.",
  },
  {
    questionId: 4,
    text: "나는 특정 행동에 매달리며\n부정적인 감정을 제거하려 한다.",
  },
  {
    questionId: 5,
    text: "나는 부정적인 경험을 애초에\n" + "차단하고자 회피한다.",
  },
];

export interface QnaAnswerOptionWithIcon {
  id: number;
  text: string;
  icon: React.ReactNode;
}

// 옵션 리스트
const options: QnaAnswerOptionWithIcon[] = [
  { id: 1, text: "아니다", icon: <XMarkIcon /> },
  { id: 2, text: "그렇다", icon: <CheckIcon /> },
];

export default function Habit_2_QnA() {
  const [searchParams] = useSearchParams();
  const questionId = parseInt(searchParams.get("q") || "1");

  const navigate = useNavigate();

  const pageKey = `habit_2`; // 페이지를 구분할 키
  const { qaList, updateQaList } = useOnboardingData(pageKey);

  const question = questionList[questionId - 1];

  const handleClick = (qId: number, answerId: number) => {
    updateQaList(qId, answerId);
    setTimeout(() => {
      if (qId < questionList.length) {
        navigate(`/onboarding_new/habit/2?q=${qId + 1}`);
      } else {
        navigate(`/onboarding_new/habit/3`);
      }
    }, 400);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "90%",
        py: "20px",
        px: "24px",
        mx: "auto",
      }}
      justifyContent="center"
    >
      <Stack
        sx={{ flex: 1 }}
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <CustomTypography
          variant="H3"
          sx={{
            mb: 2,
            textAlign: "center",
            fontWeight: 500,
            color: theme.vars.palette.Content.Strong,
          }}
        >
          {question.text}
        </CustomTypography>

        {/* Grid 레이아웃에 wrap="nowrap"을 줘서 수평 한 줄 유지 */}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{
            width: "100%",
          }}
        >
          {options.map((option) => {
            const isSelected =
              qaList.find((qa) => qa.questionId === questionId)?.answerId ===
              option.id;

            return (
              <Button
                key={option.id}
                // fullWidth 제거 → 내용 너비대로 버튼이 잡힘
                variant="SingleSlim"
                color={isSelected ? "primary" : "secondary"}
                startDecorator={option.icon}
                sx={{
                  width: "166.5px",
                  height: "100px",
                  borderRadius: "12px",
                  fontSize: "Body",
                  fontWeight: isSelected ? 700 : 500,

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 0.5,

                  "& .MuiButton-startDecorator": {
                    marginRight: 0,
                  },

                  transition: "background-color 0.3s ease",
                }}
                onClick={() => handleClick(questionId, option.id)}
              >
                {option.text}
              </Button>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}