import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const PAYMENT_DOMAIN = "payment";
export const PAYMENT_ORDER_DETAIL_ENDPOINT = "order/detail";
export const PAYMENT_STEPPAY_REQUEST_ENDPOINT = "steppay/request";
export const PAYMENT_STEPPAY_CONFIRM_ENDPOINT = "steppay/confirm";
export const PAYMENT_TOSSPAYMENTS_CONFIRM_ENDPOINT = "toss-payments/success";

export const PAYMENT_MANAGEMENT_DETAIL_ENDPOINT = "management/detail";

interface Offer {
  identifier: string;
  keyIdentifier: string;
  nonce: string;
  signature: string;
  timestamp: number;
}

export interface PaymentOrderType {
  productId: string;
  bannerType: number;
  paymentProvider: "in_app" | "toss_payments";
  offerId?: string;
  offer?: Offer;
  firstMonthOfferLinkIos?: string;
  // tosspayments
  customerKey?: string;
  customerName?: string;
  customerId?: string;
  customerEmail?: string;
  orderId?: string;
  orderName?: string;
  price?: number;
}

export interface SteppayType {
  steppayOrderCode: string;
}

export interface ManagementDetailType {
  steppaySessionKey: string;
  steppayStoreUuid: string;
}

export interface SteppayConfirmType {
  orderId: string;
  orderCode: String;
}

export interface TossPaymentsConfirmType {
  orderId: string,
  paymentKey: string,
  payAmount: number,
}

export const paymentApi = {
  getOrderDetail: () =>
    axiosInstance.get<CustomResponseFormat<PaymentOrderType>>(
      `${PAYMENT_DOMAIN}/${PAYMENT_ORDER_DETAIL_ENDPOINT}`
    ),

  confirmTossPayments: (body: TossPaymentsConfirmType) =>
    axiosInstance.post<CustomResponseFormat>(
      `${PAYMENT_DOMAIN}/${PAYMENT_TOSSPAYMENTS_CONFIRM_ENDPOINT}`,
      body
    ),

  getManagementDetail: () =>
    axiosInstance.get<CustomResponseFormat<ManagementDetailType>>(
      `${PAYMENT_DOMAIN}/${PAYMENT_MANAGEMENT_DETAIL_ENDPOINT}`
    ),
};